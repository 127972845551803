import React, {useState, useEffect } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import {ListItemIcon,ListItemText,Typography, Icon} from '@material-ui/core';
import MenuContext from '../generales/MenuContext'
import BitacoraArchivo from './BitacoraArchivo';
import EditarArchivoStatus from "./EditarArchivoStatus";


export default function MenuArchivo ({handleClose,valorCursor,archivoSelec, indice, arrayTotales, setArrayTotales, listaStatus,
                                     setArchivoMenuSelec,setprogressTotales,progressTotales, procedSeleccionado,  setSubiendo}){
    const [modalBitacora,setModalBitacora]= useState(false);  
    const [modalEditar,setModalEditar]= useState(false);  

    const modBitacora = modalBitacora ? (
                        <BitacoraArchivo modalAbierto={modalBitacora}  
                            setModalAbierto={setModalBitacora} 
                            archivo={archivoSelec}
                            titulo={"Bitácora "}/>
                        ): null;
    const modEditar = modalEditar ? (
                        <EditarArchivoStatus key ={indice} modalAbierto={modalEditar}  
                            setModalAbierto={setModalEditar} 
                            archivo={archivoSelec}
                            setArchivoMenuSelec={setArchivoMenuSelec}
                            arrayTotales={arrayTotales}
                            setArrayTotales={setArrayTotales}
                            setprogressTotales={setprogressTotales}
                            progressTotales={progressTotales}
                            indice={indice}
                            procedSeleccionado={procedSeleccionado}
                            setSubiendo={setSubiendo}
                            listaTipoStatus={listaStatus}
                            titulo={"Validación de "+archivoSelec.DocAlias}/>
                        ): null;

    function abrirModalBitacora(){
        setModalBitacora(!modalBitacora);
    }

    function abrirModalEditar(){
        if(archivoSelec.ProcesoStatusId!=2){
            setModalEditar(!modalEditar);
        }
    }


    const StyledMenuItem = withStyles((theme) => ({
        root: {
          '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.common.white,
            },
          },
        },
      }))(MenuItem);
    return (        
     
        <MenuContext valorCursor={valorCursor} handleClose={handleClose}>
            
            <Typography style={{textAlign:'center',fontWeight:550, marginLeft:6, marginRight:6}} color="primary">
                 {procedSeleccionado.ProcesoNom} {' '}
            </Typography>
            <br/>            
            {archivoSelec.ProcesoStatusId===2 || (archivoSelec.ProcesoStatusId===0  && archivoSelec.Archivo.length===0)? <StyledMenuItem onClick={abrirModalBitacora}>
                <Icon style={{marginRight:6}}>                
                    <img src={"estudio.svg"} height={23} width={23} style={{marginBottom:3}}/>
                </Icon>
                <Typography>{`Bitácora ${archivoSelec.DocAlias}`}</Typography>
            </StyledMenuItem>:null}
            {archivoSelec.ProcesoStatusId!==2? 
            <StyledMenuItem onClick={abrirModalEditar}>
                <Icon style={{marginRight:6}}>                    
                    <img src={"estudio.svg"} height={23} width={23} style={{marginBottom:3}}/>
                </Icon>
                <Typography>{`Validación de ${archivoSelec.DocAlias}`}</Typography>
            </StyledMenuItem>
            :null}                          
            {modBitacora}
            {modEditar}
        </MenuContext>      
    )
}