import React, {  useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, LinearProgress, Typography, List} from "@material-ui/core";
//import CardArchivo from "./CardArchivo";
import CardArchivo from "./CardArchivo2";
import {AuthContext} from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const useStyles = makeStyles((theme) => ({
    root: {
      //alignContent: 'center',
      marginTop : '.5em',
      paddingLeft:1.5+'rem'
    },
    botones: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginRight: '.5em',
        height:'3em'
    },
    botonSubir:{
        marginTop:'1.5em',
        height:'3em'
    },
    alturaLista:{
        minHeight: 280,
        maxHeight: window.innerHeight-220,
        overflow: 'auto',
        alignContent:'center',
        //marginTop:'1rem'
    },
}));

export default function ListaArchivos({archivos,procedSeleccionado}){
    const classes = useStyles();
    const [arrayTotales, setArrayTotales] = useState(archivos);
    const [progressTotales, setprogressTotales] = useState(0);
    const [subiendo, setSubiendo] = useState(false);
    const [listaStatus, setListaStatus] = useState([]);
    const [tarjetaSeleccionada, setTarjetaSeleccionada] = useState(-1);
    const historial = useHistory();
    const {guardarUsuario}=React.useContext(AuthContext);
    //const {arraySubidos}=React.useContext(SubidaContext);

    useEffect(()=>{
        let arrAux= 0;
                for(let ind of arrayTotales){
                    if(ind.ProcesoStatusId>0&&ind.Archivo.length>0 && ind.Direcctorio.length>0&&ind.ProcesoStatusId!==2){
                        arrAux++;
                    }
                }
        setprogressTotales(arrAux);
        
        async function getListadoStatus(){
            let auxU = process.env.REACT_APP_LINK + `/procedimiento-status-list`;
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({
              idDispositivo: "987ygubhjnjik",
            });
            let config = {
              url: auxU,
              method: "POST",
              data: dataInfo,
              headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              cancelToken: source.token,
            };
            let aux = {};
            await axios(config)
              .then(async (response) => {
                aux = response.data;
                if (aux[0].Id>-1) {
                  setListaStatus(aux);
                }
              })
              .catch(function (error) {
                console.log(`Error: ${error}`);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                  console.log('datos incorrectos' );  
                }else
                {
                  if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                      ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                  {
                    console.log("Token inválido");
                    localStorage.clear();                      
                    historial.push("/"); 
                    guardarUsuario([])                                  
                  } 
                }
              });
          }
          getListadoStatus();
    },[arrayTotales,progressTotales]);

    function generarCards(){
        const arr = [];
        for (let i = 0; i<arrayTotales.length; i++){
            var comp = <CardArchivo key={i} indice={i} archivo={arrayTotales[i]} setprogressTotales={setprogressTotales}
                        progressTotales={progressTotales} setArrayTotales={setArrayTotales} arrayTotales={arrayTotales}
                        setTarjetaSeleccionada={setTarjetaSeleccionada} tarjetaSeleccionada={tarjetaSeleccionada} listaStatus={listaStatus}
                        subiendo={subiendo} setSubiendo={setSubiendo} procedSeleccionado={procedSeleccionado}/>
            arr.push(comp);
        }
        return arr;
    }

    const mostrarArchivos = arrayTotales.length> 0 && listaStatus.length>0 ? generarCards() : null;
    //const subirArchivos = arraySubidos.length>0 ? <Button variant="contained" color="primary">Subir Todo</Button> : null;
    return( 
        <div className={classes.root}>
            {/*subirArchivos*/}
            <Box display="flex" row flexWrap="wrap">
                <Box display="flex" pr={1}>
                    <Typography component="h5" >
                        Documentación de 
                    </Typography>
                </Box>
                <Box display="flex">
                    <Typography component="h5" color="primary">
                    <b>{procedSeleccionado.ProcesoNom}</b>
                    </Typography>
                </Box>
            </Box>
            {progressTotales>0 && 
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{width:'4rem'}}>
                        <Typography>{`${progressTotales} de ${arrayTotales.length}`}</Typography>
                    </Box>
                    <Box sx={{ width: '90%'}}>
                        <LinearProgress variant="determinate" value={progressTotales/arrayTotales.length * 100}/>
                    </Box>
                </Box>
            }
            <List className={classes.alturaLista} style={{width:"100%"}} disablePadding={true}>
                {mostrarArchivos}
            </List>
        </div>
    )
}