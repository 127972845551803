import React,{useState,useEffect} from 'react';
import BuscarTrabajo  from './BusqTablaTrabajo';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import {Box,LinearProgress} from "@material-ui/core";
const BuscarInicio = () => {
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [datos,setDatos] = useState([]);     
    const [carga,setCarga] = useState(false);  

    let history = useHistory();
    useEffect(()=>{ 
        const usu= localStorage.getItem('UsuarioNom');
        let nivelUsuario =localStorage.getItem('Tipo') ;
        const source = axios.CancelToken.source();
        async function conectarJSon()  {       
            setCarga(true)
            let auxU=process.env.REACT_APP_LINKRED2 +`/afiliado-lista-profesion-full`;   
            let token =localStorage.getItem('token20') ;          
            let dataInfo = qs.stringify({            
                'idDispositivo': 'qdecaxsascasxaxsa'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {
              //  console.log("entrando ");
                aux=  response.data;                
                if (aux.resultado) 
                {   setDatos(aux.resultado);
                  //  console.log(aux.resultado);
                }                                                        
            }) 
            .catch(function (error) {
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)                 
                console.log(`Error: ${error} `);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                                   
                        history.push("/");
                    } 
                }
            });                              
            setCarga(false)            
        };
        
        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push('/');
            guardarUsuario([]);  
        }else{
            if (usuario.length===0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nivelUsuario});                                         
                    
                }
            document.title = 'Red Empleo Trabajo'                     
            guardarGenerales({...generales,Titulo:'Red Empleo Trabajo'})          
            conectarJSon();
        }
        
        return ()=> {
            source.cancel();
        }
            
        

},[]);





    const contenido= datos.length!==0? (<BuscarTrabajo datosJS={datos}   />):null
    const contenidoCarga =!carga ? (contenido ) :  (<Box pt={3}><LinearProgress/> </Box>);
    return (
        <div style={{paddingTop:1+'rem'}}>
            {contenidoCarga}
        </div>
      );
}
 
export default BuscarInicio;