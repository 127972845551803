import React, {useState, useEffect} from 'react';
import {Box,Card,CardContent,IconButton,Tooltip,Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {GeneralesContext} from '../context/GeneralesContext'
import SearchIcon from '@material-ui/icons/Search';
import IntervaloSelect from './IntervaloSelect';
import TablaNotificacion from './TablaNotificacion';
import NuevaAlerta from './NuevaAlerta';

const useStyles = makeStyles((theme)=>({
    root: {
      width: '100%',
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
      altura2: {
        height: 70,
        //width:'100%',
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura3: {
        height: 70,
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura1: {
        height: 90,        
      },
  }));

export default function NotificacionInicio(){
    const classes = useStyles();
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [buscar, setBuscar] = useState(true);
    const [actualizar, setActualizar] = useState(false)
    const [contAux, setContAux] = useState(0)
    const [tituloNuevo, setTituloNuevo] = useState("")
    const [modalNuevo, setModalNuevo] = useState(false);

  useEffect(() => {
    document.title = 'Lista de Alertas'                     
    guardarGenerales({...generales,Titulo:'Lista de Alertas'})
  }, [])

    const generarLista = e =>{
      if(contAux===0){
        setContAux(1)
      }
        setBuscar(!buscar)
    }

    function abrirModal(){
      setModalNuevo(!modalNuevo)
      setTituloNuevo("Nueva Alerta")
    }
    
    function activarActualizacion(){
      if(contAux>0){
        setActualizar(!actualizar)
      }
    }

    const modNvo = modalNuevo ? <NuevaAlerta modalAbierto={modalNuevo} setModalAbierto={setModalNuevo}
                                                            titulo={tituloNuevo} />
                                                    :null
        //Descomentar el ícono de AddCircle para ver la sección de crear                                          
    return (
      <Box>
        <Box>
          <Card className={classes.altura2}>
            <CardContent>
              <Box display="flex" flexDirection="row"> 
                {
                  /*
                  <Box>
                    <IconButton color="primary"  aria-label="add circle" 
                        component="span" onClick={abrirModal}>
                        <Tooltip title="Nueva Alerta">
                        <AddCircleOutlineIcon/>
                        </Tooltip>
                    </IconButton>                                                                                                                              
                  </Box>
                  */
                }
                <Box >
                    <IconButton color="primary"  aria-label="add circle" 
                        component="span" onClick={generarLista}>
                        <Tooltip title="Buscar">
                        <SearchIcon/>
                        </Tooltip>
                    </IconButton>                                                                                                                              
                </Box>
                <Box component="span" >                                                                                                                                            
                    <IntervaloSelect fechasSeleccionadas={fechasSeleccionadas} setFechasSeleccionadas={setFechasSeleccionadas} />
                </Box> 
                <Box pt={1} pl={1}>
                {/* <IconButton color="primary"  aria-label="add circle" 
                    component="span">
                      <Tooltip title={!actualizar ? 'Activar Actualización Automática' : "Desactivar Actualización Automática"} 
                        onClick={activarActualizacion}>
                        <RefreshIcon/>
                      </Tooltip>
                  </IconButton>   */}  
                {!actualizar ?<Tooltip title='Activar Actualización Automática'  >
                                <Button variant="contained" color="primary" size="small" onClick={activarActualizacion}>Activar</Button></Tooltip>
                  :<Tooltip title='Desactivar Actualización Automática'  >
                    <Button variant="outlined" color="primary" size="small" onClick={activarActualizacion}>Desactivar</Button></Tooltip>
                }
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box p={1}>
          <TablaNotificacion buscar={buscar} fechasSeleccionadas={fechasSeleccionadas} actualizar={actualizar} conta={contAux}/>
        </Box>
        {modNvo}
      </Box>
    )
}