import React from 'react'
//import NuevoCliente from '../clientes/NuevoCliente'
//import NuevoProcedimientoCliente from './NuevoProcedimientoCliente';
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow,Card} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';

import moment from 'moment';
//import MenuCliente from '../clientes/MenuCliente'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: 780,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      //minWidth:'100%',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    {
      id:5,
      titulo:"Etapa",                                                
      alinear:'center'
    },
    {
      id:6,
      titulo:"Estatus",                                                
      alinear:'center'
    }
    , {
      id:9,
      titulo:"F. Solicitud",                                                
      alinear:'center'
    },  
    {
      id:7,
      titulo:"Duración",                                                
      alinear:'center'
    },   
    {
      id:8,
      titulo:"F.Aceptación",                                                
      alinear:'center'
    },
    {
      id:4,
      titulo:"Procedimiento ",                                                
      alinear:'center'
    },             
     
  ];
const Tabla = ({IdCli,setProcedSeleccionado,clinombr,actualizar}) => {
  const classes = useStyles();
  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos]= React.useState(true)
  const [espera,setEspera]=React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);
  //const [open, setOpen] = React.useState(false);    
  //const [ordenSelecc, setOrdenSelecc] = React.useState(0);
  const [modalProcAbierto, setModalProcAbierto] = React.useState(false);
  //const [modalInfoCliAbierto, setModalInfoCliAbierto] = React.useState(false);
  //const [tituloProcCli, setTituloProcCli] = React.useState("");
  //const [tituloInfoCli, setTituloInfoCli] = React.useState("");
  //const [clienteSeleccionado,setClienteSeleccionado]= useState([]); 
  //const [modalNvoCliAbierto,setModalNvoCliAbierto]= useState(false); 
  //const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false); 
  
  let history = useHistory();
    
  React.useEffect(()=>
  {
    const source = axios.CancelToken.source();    
    async function conectarJSon()  {       
      setEspera(true) ;
        
      let auxU= process.env.REACT_APP_LINK +`/cliente-proceso`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                
          cliente:IdCli , //2,     
          'idDispositivo':'ifrekvjkxcdwdcwe'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;                           
        if (aux[0].Id !== -1) 
        { console.log(aux);  
          guardarSinDatos(true)                                                           
          guardarDatos(aux)
        }
        else
        { 
          guardarSinDatos(false)       
        }                                            
      })
      .catch(function (error) {                
        console.log(error);      
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
        setEspera(false)  ;
    };
          
    conectarJSon();      
    return ()=> {
        source.cancel();
    }                

  },[actualizar])
  const seleccionado=(auxId,auxProced)=>{
    setSelectedID(auxId)
    setProcedSeleccionado(auxProced)
  }
  
  
  function abrirModalProc(){
    setModalProcAbierto(!modalProcAbierto);
    //setTituloProcCli("Nuevo Procedimiento de "+clinombr);
  }
 
 
  function colorEstatus(colorEstado,valor)
  { 
    let auxColor=colorEstado
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:55+'px',paddingLeft:.5+'rem'}} 
      >
        <Card style={{color:'white', fontWeight:"bold", backgroundColor:auxColor}}>
          {valor}
        </Card>
      </TableCell>    )
    return renglon
  } 
 /*  const valorInicial = {
    mouseX: null,
    mouseY: null,
  }; */
 

  //const [valorCursor, setValorCursor] = React.useState(valorInicial);
  /* function guadarPosCursor(event,registro) {
    event.preventDefault();
    setValorCursor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    //console.log(registro);
    //setClienteSeleccionado(registro)
  }; */
    
  function Renglon(props) {
    const { row } = props;      
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
       // onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >
       {/*  <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
          {selectedID === row.Id ? <CreateIcon style={{color:'white',height:16+'px'}} />
            :<CreateIcon style={{height:16+'px'}} color="primary"/>}
          </IconButton>                                                                                                                                                                          
        </TableCell>   */}    
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:50+'px',fontSize:15+'px'}} >
          {row.Id}
        </TableCell>     
        {colorEstatus(row.ProcesoEtapaColor,row.ProcesoEtapaNom)}                       
        {colorEstatus(row.ProcesoStatusColor,row.ProcesoStatusNom)}                       
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:70+'px',fontSize:10+'px',paddingLeft:.5+'rem'}} >
          {moment.utc( row.FSolicita).format('DD/MM/YYYY HH:mm')=== "1900-01-01 00:00"
            ? '' : moment.utc( row.FSolicita).format('DD/MM/YYYY HH:mm')}
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:30+'px'}} >
          {row.Duracion}              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:70+'px',fontSize:10+'px'}} >
          {moment.utc( row.FAcepta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.FAcepta).format('DD/MM/YYYY HH:mm')}              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="Left" padding={'none'} style={{ width:220+'px',fontSize:12+'px',paddingLeft:.5+'rem'}} >
          {row.ProcesoNom}
        </TableCell>                                                                              
      </TableRow>        
    </React.Fragment>
    );
  }
  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>             
            
            <TableCell align="center" padding={'none'} style={{ color:'#0F82EE',fontWeight:600}}  >
            {/*   <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalProc()} component="span">
                <AddCircleOutlineIcon style={{color:'#0F82EE',height:20+'px',paddingBottom:.2+'rem'}}  />  
              </IconButton> */}
              <span >
                {auxlista.length}
              </span>
            </TableCell>                          
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:'#0F82EE',fontWeight:600}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!==0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
    const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>     
            <TableCell align="center"padding={'none'} style={{color:'#0F82EE'}} >
              <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalProc()} component="span">
                <AddCircleOutlineIcon style={{color:'#0F82EE',height:17+'px'}}  />  
              </IconButton>
            </TableCell>        
            {columnas.map(row => {              
              return(  
              <TableCell style={{ color:'#0F82EE',fontWeight:500}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow}> 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Procedimientos</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center" style={{paddingLeft:.5+'rem',paddingRight:.5+'rem'}}></TableCell>     
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const contenido = sinDatos && datos.length !== 0 ? tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
  
                                                                                                       
  return (
    <div>      
      {tabla}                              
    </div>
  )
}

export default Tabla
