import React,{useState,useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {Box,LinearProgress} from "@material-ui/core";
//import jwt from 'jsonwebtoken';
import TablaPost from './BusqTablaPostulante';
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
const RedEmpBuscar = () => {
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [datos,setDatos] = useState([]);
    const [carga,setCarga] = useState(false);
    let history = useHistory();    

 useEffect(()=>{ 
    const usu= localStorage.getItem('UsuarioNom');
    let nivelUsuario =localStorage.getItem('Tipo') ;
    let token =localStorage.getItem('token20') ; 
    const source = axios.CancelToken.source();
    
    const conectarJSon = async () => {    
        setCarga(false);
        let auxU=process.env.REACT_APP_LINKRED2+`/afiliado-lista`;         
        let dataInfo = qs.stringify({
        //  'usuario': id,
            'idDispositivo': 'qdecaxsascasxaxsa' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };

        let aux ={};
        //console.log("entrando a la llamada");      
        await axios(config)
        .then(function (response) {            
            aux =  response.data;  
            
            if (aux.resultado) 
            {//   console.log(aux.resultado);
                //const datos =  await aux;      
                setDatos(aux.resultado);    
            }
        })
        .catch(function (error) {
            console.log(error);
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                    console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                                    
                    history.push("/");
                } 
            }
        });
        setCarga(true);
    }

    const autenticado = authUser();
    if(!autenticado){
        localStorage.clear();
        history.push('/');
        guardarUsuario([]);  
    }else{
        if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nivelUsuario});                                         
                
            }
        document.title = 'Red Empleo Busqueda'                     
        guardarGenerales({...generales,Titulo:'Red Empleo Busqueda'})          
        conectarJSon();
    }
    return ()=> {
        source.cancel();
    }
},[]);
    const contenido = carga&&datos? (<TablaPost datosJS={datos}/>) : (<Box pt={3}><LinearProgress/> </Box>)
    return (<div style={{marginLeft:'1rem'}}>{contenido}</div>  );
}
export default RedEmpBuscar;