import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    titulo:{
        color:'#4a4a4a',
        marginTop:'-1.25rem',
        fontSize:'2rem',
    },
    subtitulo:{
        color:'#4a4a4a',
        marginTop:'-2.35rem',
        fontSize:'1.25rem',
    },
    contenedorTexto:{
        alignItems:'stretch',
        display:'block',
        flexGrow:'1',
        flexShrink:'1',
    }
}));

export default function MCardDatos2({Tipo,ListEdad}){
    const classes = useStyles();
    
    //console.log(valor);  
    let contador=0; 
    function ObtenTotal() {
        
        ListEdad.map((Edad) => {            
              contador=Edad.Total+contador;
             // nombres.push(Edad.Total+" "+Edad.Rango)                                                                                                           
      })                              
    }
    ObtenTotal();

    function llenadoTiles(nombre,datos) {
        let colorEti='etiqBlanca';
        let auxDatos =datos;        
       // console.log(nombre);     
        return(            
                <article className={classes.conten}>                
                    <p className={classes.titulo} id={colorEti}>{auxDatos}</p>                
                    <p className={classes.subtitulo}  id="ajustarTitulo">{nombre}</p>                                
                </article>                    
        );
    }
    
   /* function validandoColor(vEfecti) {
        var colorEfecti='dato';    
        if(vEfecti<60||vEfecti===0){
            colorEfecti= 'etiqRoja';        
        }
        else{
            if(vEfecti>=60 && vEfecti<80)
            {
                colorEfecti= 'etiqNar';               
            }
            else{
                if(vEfecti>=80 )
                {
                    colorEfecti= 'etiqVerd';
                }
            }
        }
        
        return(colorEfecti);
    }
    const arreglos=()=>{
      return(
        <section className="pt-5" >
        <div className="tile is-ancestor">            
          
        
        {  ListEdad.map((postulante) => {
            console.log(postulante.Rango);
                                                                                                                        
            return (                  
                <div>{llenadoTiles(postulante.Rango,<NumberFormat value={postulante.Total} displayType={'text'}  thousandSeparator={true}/>)}</div>
                
                );                        
            })
        }
        </div>
        
        </section>
        )
    }
    const arreglos2=()=>{
        return(
          <section className="pb-6 " >
              
          <div className="tile is-ancestor ">            
            
          
          {  Tipo.map((postulante) => {
             // console.log(postulante.Trabajo);
                                                                                                                          
              return (                  
                  <div>{llenadoTiles(postulante.Trabajo,<NumberFormat value={postulante.Total} displayType={'text'}  thousandSeparator={true}/>)}</div>                  
                  );                        
              })
          }
          </div>
          
          </section>
          )
  
      }*/
    const arreglos3=()=>{
    return(
        <section style={{display:'flex', justifyContent:'center'}} >
        <div style={{marginBottom:'-0.75rem', display:'flex '}}>                            
            <div>{llenadoTiles("Afiliados",<NumberFormat value={contador} displayType={'text'}  thousandSeparator={true}/>)}</div>        
        </div>
        
        </section>
        )

    }
    const auxContenido2 = ListEdad?(arreglos3()):null
   // const auxContenido = Tipo?(<div>{arreglos2()}</div>):null
    return ( 
        
        <section style={{display:"flex",paddingBottom:'.5rem',justifyContent:'center',}} >            
            <div style={{paddingBottom:'.15rem'}}>{auxContenido2}</div>            
            {/*<h1 className="is-size-2 pb-3">Totales</h1>
            <h1 className="is-size-2 pt-6">Rango de Edad</h1>
             auxContenido */}
        </section>
         );
}
 