import React, {useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Card,CardContent,Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    width:'100%',
    paddingRight:'3rem'
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    color:'#4a4a4a',
    maxWidth:'100%',
    position:'relative'
  },
  tituloGraf:{
    paddingTop:'0rem',
    marginTop:'1rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


const Graficas = ({Tipo,ListEdad}) => {
  const classes = useStyles();
    let color1= '#FFD204';
    let color2= '#06c12d';
    let color3= '#e71515';
    let color4= '#f08e1f';
    let color5= '#10b0cd';
    let color6= '#d8d027';
    let color7= '#cd1024';
    let color8= '#cd4110';
    let color9= '#10b0cd';
    let color10= '#c027d8';
    const [nombres,setNombres] = useState([])
    const [totales, setTotales] = useState([]);
    const [nombreAfil, setNombreAfil] = useState([]);
    const [totales2, setTotales2] = useState([])



    useEffect(()=>{
      let contador=0;
      let contador2=0;
      function ObtenTotal() {
        let AuxNom = []
        let AuxTot = []
            ListEdad.map((Edad) => {            
                  contador=Edad.Total+contador;
                  AuxNom.push(Edad.Total+" "+Edad.Rango)   
                  return(Edad)                                                                                                        
          })
                            
          ListEdad.map((Edad) => {                                             
            AuxTot.push(contador-Edad.Total)  
            return contador-Edad.total;                                                                                                         
          })
          setNombres(AuxNom)
          setTotales(AuxTot)
      }
      function ObtenAfil() 
      {   
        let AuxNomAfil = []
        let AuxTot2=[]
        Tipo.map((Afiliado) => {            
              contador2=Afiliado.Total+contador;
              AuxNomAfil.push(Afiliado.Total+" "+Afiliado.Trabajo.toUpperCase())           
            return(Afiliado)
          })
                          
        Tipo.map((Afiliado) => {                                                
          AuxTot2.push(contador2-Afiliado.Total)                                                                                                           
        })
        setNombreAfil(AuxNomAfil)
        setTotales2(AuxTot2)
      }
  
      ObtenTotal()
      ObtenAfil()

    },[])
    const data = {
        labels: nombres,
        datasets: [{
          data: totales,
          backgroundColor: [
          color1,
          color2,
          color3,
          color4,
          color5,color6
          
          ],
          hoverBackgroundColor: [
            color1,
            color2,
            color3,
            color4,
            color5,color6
          ]
        }]
      };
      const data2 = {
        labels: nombreAfil,
        datasets: [{
          data: totales2,
          backgroundColor: [
          color1,
          color2,
          color3,
          color4,
          color5,color6,
          color7,color8,
          color9,color10,
          
          ],
          hoverBackgroundColor: [
            color1,
            color2,
            color3,
            color4,
            color5,color6,
          color7,color8,
          color9,color10,
          ]
        }]
      }; 
      /*const grafica2 = {
        labels: [
          EfecProm,
          EfecMeta
        ],
        datasets: [{
          data: [valor.efectividad, metfinalt2],
          backgroundColor: [
            color1,
            color2
          
          ],
          hoverBackgroundColor: [
            color1,
            color2
          ]
        }]
      };*/
      const options = {
        legend: {
          display: true,
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 5
          }
        },
        title:{
          fontStyle: 'bold',
        },
      
      }
      function  llenadoTabla(datos,nombre)
      {
        return(
            <Card className={classes.card}>
                <CardContent>
                {<h2 className={classes.tituloGraf}>{nombre}</h2>}
                  <Doughnut
                  data={datos}
                  width={350}
                  height={350}  
                  options={options}
                  />
                </CardContent>
            </Card>
        );
      }
    return (
        <Box display="flex" flexDirection="row">      
                    <div className={classes.contenedorCard}>
                    {llenadoTabla(data2,'Top 10 Servicios') }
                        
                    </div>
                    <div className={classes.contenedorCard}>
                    {llenadoTabla(data,'Edad')}
                    </div>
        </Box>
      );
}
 
export default Graficas;