import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import { Box, LinearProgress } from "@material-ui/core";
import {ClienteContext} from '../context/ClienteContext'
import ListaArchivos from "./ListaArchivos";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const useStyles = makeStyles((theme) => ({
    root: {
      alignItems: 'center',
      width: 600
    },
    cerrarSesion:{
        marginBottom:'1em',
    }
}));

export default function ArchivosInicio({procedSeleccionado}){
    const historial = useHistory();
    const {clientSeleccionado}=React.useContext(ClienteContext);  
    const [auxCliente,setAuxCliente]=React.useState([])
    const [espera, setEspera] = useState(false);
    const [listaArchivos, setListaArchivos] = useState([]);
    const {guardarUsuario}=React.useContext(AuthContext);

    useEffect(()=>{
      async function getListadoArchivos(cliente){
        setEspera(true);
        let auxU = process.env.REACT_APP_LINK + `/procedimiento-uploadfile-list`;
        let token = localStorage.getItem("token20");
        let dataInfo = qs.stringify({
          procesoCliente: procedSeleccionado.Id, //1 ,
          usuario:cliente.IdCli,
          idDispositivo: "987ygubhjnjik",
        });
        let config = {
          url: auxU,
          method: "POST",
          data: dataInfo,
          headers: {
            "access-token": token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        };
        let aux = {};
        await axios(config)
          .then(async (response) => {
            aux = response.data;
            console.log(procedSeleccionado.Id);
            console.log(aux);
            if (aux[0].CliDocId>-1) {
              setListaArchivos(aux); 
            }
          })
          .catch(function (error) {
            console.log(`Error: ${error}`);
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
              console.log('datos incorrectos' );  
            }else
            {
              if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                  ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
              {
                console.log("Token inválido");
                localStorage.clear();                      
                historial.push("/");                              
              } 
            }
          });
          setEspera(false);
      }

      const autenticado = authUser();
      if(!autenticado){
        localStorage.clear();
        historial.push('/');
        guardarUsuario([]);    
      }else{
        let cliDat = localStorage.getItem('cliDat');
        let cliDat2=JSON.parse(cliDat)
        //console.log(cliDat2);
        if (clientSeleccionado.length==0 &&cliDat2) 
        {
            setAuxCliente(cliDat2)
            getListadoArchivos(cliDat2);
        }
        else
        {
            setAuxCliente(clientSeleccionado)
            getListadoArchivos(clientSeleccionado);
        }
      }
      
    },[procedSeleccionado]);

    const mostrarComponenteListado = listaArchivos.length > 0 ? 
                                      <ListaArchivos archivos={listaArchivos} procedSeleccionado={procedSeleccionado}/> 
                                      : null;
    const listado = espera ?  (<Box pt={3}>
                                <LinearProgress/> 
                                </Box>) 
                              :( mostrarComponenteListado )
      return(
          <div>
          {listado}
          </div>
      )
}