import React from 'react'
import {List,ListItem,Collapse,Typography,
        ListItemText,Divider,} from "@material-ui/core";
import {ExpandLess,ExpandMore  } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      
    },
    nested: {
      paddingLeft: theme.spacing(4),
      
    },
  }));

const Listas = () => {
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const cambiar = (opcion) => {
        if (opcion===1) 
        {
            setOpen(!open);    
        }else
        {
            if (opcion===2) 
            {
                setOpen2(!open2);    
            }else
            {
                if (opcion===3) 
                {
                    setOpen3(!open3);    
                }
                else
                {
                    if (opcion===4) 
                    {
                        setOpen4(!open4);    
                    }   
                }
            }   
        }
        
      };
    const nuevaVentana =(elLink)=>{
        //console.log("entrando");
        history.push(elLink)	
    }
    const contacto=(e)=> {
        e.preventDefault()
        window.open("https://daduva.mx");}
    return (
        <div>
            <List component='nav'>                
                <ListItem button onClick={()=>cambiar(1)}>                    
                    <ListItemText primary="Menu" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div"  disablePadding>                        
                        <ListItem button className={classes.nested}
                            onClick={()=>nuevaVentana('/lista-usuario')} 
                        >                    
                            <ListItemText    
                                secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Usuarios</Typography>} 
                            />
                        </ListItem> 
                        <ListItem button className={classes.nested}
                            onClick={()=>nuevaVentana('/bitacora')} 
                        >                    
                            <ListItemText    
                                secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Alertas</Typography>} 
                            />
                        </ListItem> 
                                                                      
                        {/* <ListItem button className={classes.nested}>                    
                            <ListItemText  secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Promotor</Typography>}
                            />
                        </ListItem>    */}                                             
                    </List>
                </Collapse>
                <Divider/>
                {/* <ListItem button onClick={()=>cambiar(2)}>                    
                    <ListItemText primary="Red de Empleo" />
                    {open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div"  disablePadding>                        
                    <ListItem button className={classes.nested}
                            onClick={()=>nuevaVentana('/red-emp-inicio')} 
                        >                    
                            <ListItemText    
                                secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Inicio</Typography>} 
                            />
                        </ListItem> 
                        <ListItem button className={classes.nested}
                            onClick={()=>nuevaVentana('/red-emp-trabajo')} 
                        >                    
                            <ListItemText    
                                secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Trabajo</Typography>} 
                            />
                        </ListItem> 
                        <ListItem button className={classes.nested}
                            onClick={()=>nuevaVentana('/red-emp-busq')} 
                        >                    
                            <ListItemText    
                                secondary={<Typography variant="p" style={{ color: 'white' }}>
                                    Busqueda</Typography>} 
                            />
                        </ListItem> 
                                             
                    </List>
                </Collapse>
                <Divider/> */}
                <ListItem button onClick={contacto} >                    
                    <ListItemText  
                        secondary={<Typography variant="p" style={{ color: 'white' }}>
                        Contacto</Typography>}
                    />                    
                </ListItem>
            </List>
        </div>
    )
}

export default Listas
