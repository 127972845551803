import React,{useState} from 'react'
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow,Button,Card,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
 
import CreateIcon from '@material-ui/icons/Create';
 
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format'
import axios from 'axios';
import qs from 'qs';
//import InfoCliente from '../clientes/InfoCliente';
import moment from 'moment';
import EditarUsuario from './EditarUsuario'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'

const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    {
      id:4,
      titulo:"No.",                                                
      alinear:'center'
    },     
    {
      id:5,
      titulo:"Usuario",                                                
      alinear:'center'
    },
    {
      id:15,
      titulo:"Sección",                                                
      alinear:'center'
    },
    {
      id:14,
      titulo:"Colonia",                                                
      alinear:'center'
    },
    {
      id:12,
      titulo:"CURP",                                                
      alinear:'center'
    },
    {
      id:7,
      titulo:"INE",                                                
      alinear:'center'
    }, 
    {
      id:11,
      titulo:"RFC",                                                
      alinear:'center'
    },   
    {
      id:13,
      titulo:"Telefono",                                                
      alinear:'center'
    },   
    {
      id:16,
      titulo:"Celular",                                                
      alinear:'center'
    },      
    {
      id:8,
      titulo:"Email",                                                
      alinear:'center'
    },
    {
      id:9,
      titulo:"Edad",                                                
      alinear:'center'
    },
    {
      id:6,
      titulo:"Genero",                                                
      alinear:'center'
    },
     
    {
      id:10,
      titulo:"F. Reg.",                                                
      alinear:'center'
    },        
  ];
const TablaUsuarios = ({auxNombre,auxActualizar,setActualizar}) => {
    const classes = useStyles();
    const [datos, guardarDatos] = React.useState([])  
    const[sinDatos,guardarSinDatos]= React.useState(true)
    const [espera,setEspera]=React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);
    const [open, setOpen] = React.useState(false);    
    const [ordenSelecc, setOrdenSelecc] = React.useState(0);
    const [modalProcAbierto, setModalProcAbierto] = React.useState(false);
    const [modalInfoCliAbierto, setModalInfoCliAbierto] = React.useState(false);
    const [tituloProcCli, setTituloProcCli] = React.useState("");
    const [tituloInfoCli, setTituloInfoCli] = React.useState("");
    const [clienteSeleccionado,setClienteSeleccionado]= useState([]); 
   
    const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false); 
    //const[actualizar,setActualizar]=useState(true);
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    let history = useHistory();     
    const[auxCliente,setAuxCliente]=React.useState([])    
    
    React.useEffect(()=>
    {
      const source = axios.CancelToken.source();    
      async function conectarJSon()  {       
        setEspera(true) ;
        
        let auxU= process.env.REACT_APP_LINKTEZO +`/usrpublico-list`;               
        let token =localStorage.getItem('token20') ;               
        let dataInfo = qs.stringify({                                 
            'usuario':1,
            'cadena':auxNombre,
            'idDispositivo':'ifrekvjkxcdwdcwe'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            // headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {                
            aux=  response.data;      
            console.log(aux);                     
            if (aux[0].Id != -1) 
            { // console.log(aux);  
              guardarSinDatos(true)                                                           
              guardarDatos(aux)
            }
            else
            { 
              guardarSinDatos(false)       
            }                                            
        })
        .catch(function (error) {                
          console.log(error);        
          if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
          {
            console.log('datos incorrectos' );  
          }else
          {
            if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
            {
              console.log('loguearse de nuevo');
              localStorage.clear();                      
              history.push("/");                              
            } 
          }
        });              
        setEspera(false)  ;
      };
    const usu= localStorage.getItem('UsuarioNom');
    const usuID= localStorage.getItem('UsuId');

    const nvl= localStorage.getItem('Tipo');    
    const veriSesion =  () => {            
        let auxValidacion=authUser()
        if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
            ||(usuID===undefined||usuID===''||usuID===null) )
        {   guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else
        {   
            if (usuario.length==0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                         
                
            }        
            document.title = 'Listado Usuarios'                                 
            guardarGenerales({...generales,Titulo:'Listado Usuarios'})   
            conectarJSon();                                              
        }            
    }        
  //veriSesion();	      
  conectarJSon()
  return ()=> {
      source.cancel();
  }                
  },[auxActualizar])
  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
  }
  
  
  
   
  function abrirModalEditCliente(auxClient)
  {  setClienteSeleccionado(auxClient)
    console.log("abriendo modal");  
    setModalEditCliAbierto(true);    
  }
  
  function tipoEdoProspecto(estado,etiqueta)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado==1) 
    {
        auxEtiqueta=etiqueta
        auxColor='#0EA7E0'
    }
    else
    {
      if (estado==2) 
      {
      auxEtiqueta=etiqueta
      auxColor='#049b4f'
      }
      else
      {
      if (estado == 0) 
      {
          auxEtiqueta=etiqueta
          auxColor='#3f9bf1'
      } 
      }
    }
    
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:'white', fontWeight:"bold", backgroundColor:auxColor}}>
          {auxEtiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
  const valorInicial = {
    mouseX: null,
    mouseY: null,
  };
  const cerrarMenu = () => {
      setValorCursor(valorInicial);
  };

  const [valorCursor, setValorCursor] = React.useState(valorInicial);
  function guadarPosCursor(event,registro) {
    event.preventDefault();
    setValorCursor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    //console.log(registro);
    setClienteSeleccionado(registro)
  };
  function addCero (valor)
  { console.log(valor);
    let auxValor=0
    let valorStr=valor.toString()
    let ini=valorStr.indexOf(".")
    if (ini!== -1) 
    {   // console.log(valorStr);  
      let cortado=valorStr.substr(ini,valor.length)
     
      if (cortado.length == 2  ) 
      {
        auxValor=(valorStr+"0")
 
      }
      else
      {auxValor=valor+0.00  }
    }
    else{
 
      auxValor=valor+0.00      
    }
    //console.log(auxValor);
    return auxValor;
  }  
  function Renglon(props)
  {
    const { row } = props;      
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      > 
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Editar Usuario`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
              {selectedID === row.Id ? 
                <CreateIcon style={{color:'white',width:18+'px'}} />
                :<CreateIcon style={{width:18+'px'}} color="primary"/>
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell>      
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:40+'px', paddingRight:2+'px'}} >
          {row.Id}
        </TableCell>     
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:250+'px',fontSize:12+'px'}} >
          {row.Nombre+' '+row.Paterno+' '+row.Materno}
        </TableCell>  
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.SeccionFolio  }
        </TableCell>   
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.ColoniaNom  }
        </TableCell>                                                         
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:150+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.Curp}
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:150+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.Ine  }
        </TableCell>        
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:90+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.Rfc}              
        </TableCell>   
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.Tel}              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'}} >
          {row.Cel}              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:120+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.Email}              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:30+'px',fontSize:12+'px',paddingLeft:5+'px'}} >
          {row.Edad}
        </TableCell>
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:40+'px',paddingLeft:.5+'rem'}} >
          {row.Genero}
        </TableCell>                                                       
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.Freg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.Freg).format('DD/MM/YYYY HH:mm')}       
        </TableCell>              
                         
      </TableRow>        
    </React.Fragment>
  );}
  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  let colorLetra="#0F82EE"
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>                                     
            <TableCell align="center" padding={'none'} style={{ color:colorLetra}}  >
              {auxlista.length}
            </TableCell>                          
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:colorLetra}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
    const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            {columnas.map(row => {              
              return(  
              <TableCell style={{ backgroundColor:'#0F82EE',color:'white'}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow 
          className={classes.tableRow}
        > 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Usuarios</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
/*   const modInfoClie = modalInfoCliAbierto ? 
                                        ( <InfoCliente modalAbierto={modalInfoCliAbierto}  setModalAbierto={setModalInfoCliAbierto} 
                                            cliente={clienteSeleccionado}  titulo={tituloInfoCli}
                                          />
                                        ): null;   */
  
  const modEditCli = modalEditCliAbierto ? 
                      ( <EditarUsuario modalAbierto={modalEditCliAbierto}  
                          setModalAbierto={setModalEditCliAbierto} usrSelecc={clienteSeleccionado}
                          titulo={'Editar '+clienteSeleccionado.Nombre+' '+clienteSeleccionado.Paterno+' '+clienteSeleccionado.Materno}                          
                          setActualizar={setActualizar} actualizar={auxActualizar}
                        />
                      ): null;                                                                                                          
  return (
    <div style={{minWidth:1215+'px',marginTop:.5+'rem'}}>        
      {tabla}
      
      {modEditCli}
    </div>
  )
}

export default TablaUsuarios
