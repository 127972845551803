import React, {  useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Card, Box,Typography, Tooltip, IconButton } from "@material-ui/core";
//import {SubidaContext} from "../context/SubidaContext";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoArchivo from "./InfoArchivo";
import MenuArchivo from './MenuArchivo'
import { useHistory } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import CloudUpload from '@material-ui/icons/CloudUpload';
import {AuthContext} from "../context/AuthContext";
import Progress from "./Progress";
import moment from "moment";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const useStyles = makeStyles((theme) => ({
    tarjetas:{
        marginBottom: '1em', height:45, minHeight:45,width:'25rem',  maxHeight:60,marginLeft:'3px'
    },
    tarjetaSeleccionada:{
        marginBottom: '1em', height:45, minHeight:45,width:'25rem',  maxHeight:60, boxShadow: "3px 4px gray",marginLeft:'3px'
    },
    botones: {
        marginLeft:'19%',marginTop: '.5em',height:'1.5em',
    },
    botonVer: {
        marginTop: '.5em',height:'1.5em',marginLeft:'30%'
    },
    colorPendiente:{
        backgroundColor: '#F6AC23', width:'30%',height:45
    },
    colorRevision:{
        backgroundColor: '#0EA7E0', width:'30%',height:45
    },
    colorRechazado:{
        backgroundColor: 'red', width:'30%',height:45
    },
    colorAprobado:{
        backgroundColor: '#049b4f' , width:'30%',height:45
    },
    fuenteTamanio:{
        fontSize:12,
        height:'2em',
    },
    fuenteTamanioArchivo:{
        marginTop:'.1em',
        fontSize:11,
    },
    fuenteTamanioStatus:{
        fontSize:12,marginTop:13,
        color:'white', fontWeight:'bold'
    },
    fuenteTamanioStatusAprobado:{
        fontSize:12,marginTop:13, marginLeft:25,
        color:'white', fontWeight:'bold'
    },
}));

    
export default function CardArchivo({indice, archivo, setArrayTotales,arrayTotales, setprogressTotales, listaStatus,
                                    progressTotales, subiendo, setSubiendo,procedSeleccionado, setTarjetaSeleccionada, tarjetaSeleccionada}){
    const [modalAbierto,setModalAbierto]= useState(false);
    const [archivoCard, setArchivoCard] = useState(archivo);
    const [archivoSubida, setArchivoSubida] = useState();
    const [progresoEnvio, setProgresoEnvio] = useState();
	const [archivoSeleccionado, setArchivoSeleccionado] = useState(false);
    const [archivoMenuSelec, setArchivoMenuSelec] = useState(null);
   // const {arraySubidos, guardarArraySubidos}=React.useContext(SubidaContext);
    const [espera, setEspera] = useState(false);
    const historial = useHistory();
    const classes = useStyles();
    const {guardarUsuario}=React.useContext(AuthContext);
    
    useEffect(()=>{
        setArchivoCard(archivo)
        if(archivo.CliDocId===49){

            console.log(archivo)
        }
        if(subiendo){
            setEspera(subiendo);
        }else{
            setEspera(false);
        }
        
    },[subiendo,progresoEnvio])

    const valorInicial = {
        mouseX: null,
        mouseY: null,
    };
    const [valorCursor, setValorCursor] = useState(valorInicial);


    function guadarPosCursor(event,registro,indice) {
        event.preventDefault();
        setValorCursor({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        setArchivoMenuSelec(registro)
        if (indice !== tarjetaSeleccionada) {
            setTarjetaSeleccionada(indice);
        }
    };
    
    const cerrarMenu = () => {
        setValorCursor(valorInicial);
        setTarjetaSeleccionada(-1)
    };

    function verArchivo(archivo, indice){
        setArchivoSeleccionado(archivo);
        setModalAbierto(!modalAbierto);
        if (indice !== tarjetaSeleccionada) {
            setTarjetaSeleccionada(indice);
        }
    }

    const changeHandler = (event) => {
        /*
            if(event.target.files[0] &&event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)){
                setArchivoSubida(event.target.files[0]);
                setArchivoSeleccionado(true);
                setEspera(false)
                //guardarArraySubidos([...arraySubidos, event.target.files[0]])
            }else{
                console.log("sin archivo")
                setArchivoSeleccionado(false);
                setArchivoSubida();
            }
        */
            var tipo = archivoCard.DocExt.toLowerCase();
            var regex;
            if(tipo==="pdf"){
                regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.pdf)$/
            }
            if(tipo==="doc"||tipo==="docx"){
                regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.doc|.docx)$/
            }
            if(tipo==="xls"||tipo==="xlsx"){
                regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.xls|.xlsx)$/
            }
            if(event.target.files[0] && event.target.files[0].name.toLowerCase().match(regex)){
                setArchivoSubida(event.target.files[0]);
                setEspera(true);
                let nom ="";
                if(event.target.files[0].name.length>=25){
                    nom =  event.target.files[0].name.substring(0,24)+"..."+event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf("."),event.target.files[0].name.length);
                }else{
                    nom = event.target.files[0].name;
                }
                toast.info(`Documento Seleccionado ${nom}`,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `arc-sel${indice}`
                  });
                subirArchivo(event.target.files[0]) 
                //guardarArraySubidos([...arraySubidos, event.target.files[0]])
            }else{
                toast.warning("Documento Incorrecto, seleccione un documento " + archivoCard.DocExt,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `incorrecto-sel${indice}`
                  }); 
                setArchivoSubida();
            }
            };
    async function subirArchivo(arcSub){     
        setEspera(true);
        setSubiendo(true);
        let auxU=process.env.REACT_APP_LINK +`/procedimiento-uploadfile`;                               
        let token =localStorage.getItem('token20'); 
        let usuario = localStorage.getItem('UsuId');
        var bodyFormData = new FormData();
        bodyFormData.append('usuario',usuario);
        bodyFormData.append('Proceso',procedSeleccionado.Id);
        bodyFormData.append('DocId',archivoCard.CliDocId);
        bodyFormData.append('descr','ok');
        bodyFormData.append('idDispositivo','987yugbhcdsscs');
        bodyFormData.append('profile',arcSub);
        let config = {
            url:auxU ,
            method: 'POST',
            headers: {
                'access-token' : token,
                'size': arcSub.size.toString(),
                'Content-Type': 'multipart/form-data'
            },
            data: bodyFormData,
            cancelToken: source.token,
            maxBodyLength: 100 * 1024 * 1024,
            onUploadProgress: data => {
                let progreso= ((100 * data.loaded) / data.total).toFixed(2)
                setProgresoEnvio(progreso)
            },
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux =  response.data; 
          if (aux.success) 
          {                
            /*let TotalesBotonSubir = arraySubidos;
            let index = TotalesBotonSubir.indexOf(archivoSubida);
            console.log(indice)
            TotalesBotonSubir.splice(index,1);
            guardarArraySubidos(TotalesBotonSubir); */ 
            setArchivoSubida();
            setProgresoEnvio();
            var fechaNueva = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]');
            let arrAux =arrayTotales;
            let extensionLength = aux.informacion.filename.length;
            let indicePunto = aux.informacion.filename.lastIndexOf(".");
            let nuevoAux = 
            {
                Anotacion: archivo.Anotacion,
                Archivo: aux.informacion.filename,
                CliDocId: archivo.CliDocId,
                Direcctorio: aux.informacion.dir,
                DocAlias: archivo.DocAlias,
                DocDescr: archivo.DocDescr,
                DocExt: aux.informacion.filename.substring(indicePunto+1,extensionLength).toUpperCase(),
                DocNota: archivo.DocNota,
                DocTipoId: archivo.DocTipoId,
                FAcepta: archivo.FAcepta,
                FActualiza: archivo.FActualiza,
                FEnvio: fechaNueva,
                FMaxima: archivo.FMaxima,
                FRechazo: archivo.FRechazo,
                FSolicita: archivo.FSolicita,
                Fecha: archivo.Fecha,
                ProcesoDocId: archivo.ProcesoDocId,
                Rechazo: archivo.Rechazo,
                UsuarioId: archivo.UsuarioId,
                UsuarioNom: archivo.UsuarioNom,
                ProcesoStatusId: 1,
                Size: aux.informacion.size,
                Extension: aux.informacion.filename.substring(indicePunto+1,extensionLength),
            }
            arrAux.splice(indice,1,nuevoAux);
            let progreso = progressTotales+1;
            setprogressTotales(progreso);
            setArrayTotales(arrAux);
            toast.success("Documento Subido Exitosamente",{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `exito-env${indice}`
              })
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' ); 
                toast.error("Ha ocurrido un Error, intente más tarde",{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `error-env${indice}`
                  }); 
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();
                guardarUsuario([])                    
                historial.push("/");                              
                } 
            }
        }); 
        setEspera(false);   
        setSubiendo(false);        
    };

    function generarColor(){
        let aux = <div></div>
        if(archivo.ProcesoStatusId===0){
            aux =   <Card className={classes.colorPendiente}>
                        <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:20}}>Pendiente</Typography>
                    </Card>;
        }
        if(archivo.ProcesoStatusId===1){
            aux =   <Card className={classes.colorRevision}>
                        <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:20}}>Por Revisar</Typography>
                    </Card>
        }
        if(archivo.ProcesoStatusId===2){
            aux =  <Card className={classes.colorRechazado}>
                        <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:22}}>Rechazado</Typography>
                    </Card>
        }
        if(archivo.ProcesoStatusId===3){
            aux =  <Card className={classes.colorAprobado}>
                        <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:25}}>Aprobado</Typography>
                    </Card>
        }
        return aux
    }


    function generarCard(){
        var com=<div></div>;
        if((archivoCard.ProcesoStatusId>=0 && archivoCard.Archivo.length===0 && archivoCard.Direcctorio.length===0)){
            com = <Card className={classes.tarjetas} sx={{ display: 'flex'}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
                            <Card className={classes.colorPendiente}>
                                    <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:20}}>Pendiente</Typography>
                            </Card>
                            <Box sx={{ display: 'flex', flexDirection: 'row', width:'3%', marginRight:'.7em'}}>
                                    <input type="file" name="file" onChange={changeHandler} hidden id={`cargarArchivo${indice}`} disabled={subiendo}
                                    accept={archivoCard.DocExt.toUpperCase()!=="PDF" ? `.${archivoCard.DocExt.toLowerCase()}` : "application/pdf"}/>
                                    <label htmlFor={`cargarArchivo${indice}`}>
                                    <Tooltip title={`Seleccionar Documento ${archivoCard.DocExt}`}>
                                        <IconButton variant="outlined" component="span" id={`cargando${indice}`} disabled={subiendo}
                                        className={classes.botones} size="small">
                                            <CloudUpload color={progresoEnvio ? "primary" : ""}/>
                                        </IconButton>
                                    </Tooltip>
                                    </label>
                            </Box> 
                            <Box style={{width:'70%', display:'flex', flexDirection:'column', marginLeft:'1.5em', marginTop:'.5em'}}>
                                        {progresoEnvio==100 ?
                                            <Typography variant="inherit" className={classes.fuenteTamanioArchivo} style={{marginTop:'8.1px'}}>Espere Por favor...</Typography>
                                            : <Typography variant="inherit" className={classes.fuenteTamanioArchivo} style={{marginTop:'8.1px'}}>{archivoCard.DocAlias}</Typography>}
                                        {progresoEnvio>0 ? (
                                            <Progress progress={progresoEnvio}/>
                                        ):null}
                            </Box>
                        </Box>  
            </Card>
        }else{
                com = <Card className={tarjetaSeleccionada === indice ? classes.tarjetaSeleccionada : classes.tarjetas} onContextMenu={(event)=>guadarPosCursor(event,archivoCard, indice)}>
                            {archivoCard.ProcesoStatusId!==2 ? 
                                <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
                                        {generarColor()}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width:'3%', marginRight:'.2em'}}>
                                            <Tooltip title="Ver Documento">
                                                <IconButton variant="outlined" className={classes.botonVer} size="small" color="primary" 
                                                onClick={()=> {verArchivo(archivoCard, indice)}}><InfoIcon/></IconButton>
                                            </Tooltip> 
                                        </Box>
                                    <Box style={{width:'70%', display:'flex', flexDirection:'column', marginLeft:'1.5em', marginTop:'.5em'}}>
                                        <Typography variant="inherit"  className={classes.fuenteTamanioArchivo}>{archivoCard.DocAlias}</Typography>
                                        <Tooltip title="Descargar Documento">
                                            <Typography variant="inherit" className={classes.fuenteTamanioArchivo} color="primary">
                                                <a href={`${archivoCard.Direcctorio}/${archivoCard.Archivo}`} target="_blank">
                                                    {archivoCard.Archivo.length>40? archivo.Archivo.substring(0,30)+"..."+archivoCard.Archivo.substring(archivoCard.Archivo.lastIndexOf("."),archivoCard.Archivo.length) : archivoCard.Archivo}
                                                </a>
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </Box> 
                            :
                                     <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
                                        <Card className={classes.colorRechazado}>
                                                <Typography className={classes.fuenteTamanioStatus} style={{marginLeft:22}}>Rechazado</Typography>
                                        </Card>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', width:'3%', marginRight:'.7em'}}>
                                            <input type="file" name="file" onChange={changeHandler} hidden id={`cargarArchivo${indice}`} disabled={subiendo}
                                            accept={archivoCard.DocExt.toUpperCase()!=="PDF" ? `.${archivoCard.DocExt.toLowerCase()}` : "application/pdf"}/>
                                            <label htmlFor={`cargarArchivo${indice}`}>
                                            <Tooltip title={`Seleccionar Documento ${archivoCard.DocExt}`}>
                                                <IconButton variant="outlined" component="span" id={`cargando${indice}`} disabled={subiendo}
                                                    className={classes.botones} size="small">
                                                    <CloudUpload color={progresoEnvio ? "primary" : ""}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </label>
                                        </Box> 
                                        <Box style={{width:'70%', display:'flex', flexDirection:'column', marginLeft:'1.5em', marginTop:'.5em'}}>
                                            {progresoEnvio==100 ?
                                            <Typography variant="inherit" className={classes.fuenteTamanioArchivo} style={{marginTop:'8.1px'}}>Espere Por favor...</Typography>
                                            : <Typography variant="inherit" className={classes.fuenteTamanioArchivo} style={{marginTop:'8.1px'}}>{archivoCard.DocAlias}</Typography>}
                                            {progresoEnvio>0 ? (
                                            <Progress progress={progresoEnvio}/>
                                            ):null}
                                        </Box>
                                    </Box>         
                            }
                        </Card>
            }
        return com;
    }

    const modalInfoArch = modalAbierto ? <InfoArchivo modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} indice={indice} archivo={archivoSeleccionado} procedSeleccionado={procedSeleccionado} />:null;
    const mostrarArchivo = archivoCard!==null ? generarCard() : null;
    const menuArchivo = valorCursor.mouseY !== null ? <MenuArchivo valorCursor={valorCursor} handleClose={cerrarMenu} arrayTotales={arrayTotales} procedSeleccionado = {procedSeleccionado} listaStatus={listaStatus}
    setArrayTotales={setArrayTotales} archivoSelec={archivoMenuSelec} setArchivoMenuSelec={setArchivoMenuSelec} progressTotales={progressTotales} setprogressTotales={setprogressTotales} indice={indice} setSubiendo={setSubiendo}/>:null                                

    return(
        <Box>
            {mostrarArchivo}
            {modalInfoArch}
            {menuArchivo}
        </Box>
    )
}