import React,{useState} from 'react';
//import ModalDatGen from './BusqDatGenModal';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: '68rem',
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      overflowX:'auto'
      //minWidth:'100%',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
    width:'30em'
    },
    titulos:{
        padding: 0.2 +'rem', 
        color:'#0F82EE',
        fontWeight:600
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

const BusqTablaPostulante = ({datosJS}) => {
    const classes = useStyles();
    let datos = datosJS;
    let contador=datos.length;    
    const [seleccionado,setSeleccionado]=  useState("");      
    const [promotor,guardarPromotor] = useState({ nombre:''});
    //const [registro,setRegistro]=useState("");
    //const[infoPromot,setinfoPromot]=useState("");
    //const [mensaje2,setMensaje2]=useState("");

    const RenglonNum =(valorId,regis)=>{
        setSeleccionado(valorId);
        //let info=datos.filter((dat)=>dat.Id=== valorId) ;
       
        //setRegistro(regis);
        //setinfoPromot(info[0]);
    }

    /*const llamdaModal2=()=>{
        setMensaje2("is-active")
    }*/
    function filtro(){        
        let auxCadena=nombre.trim();    
        let info=[];
        if (auxCadena===" "||auxCadena==="") {
            info=[{Celular: " ",
            Email: "@",            
            Foto: "",            
            Id: 0,            
            Independiente: 3,
            Instagram: "",                        
            Nacimiento: " ",            
            NombreFull: "",                            
            Telefono: "",            
            TipoNom: "",
            Trabajo: "",
            Twitter: ""}]
            contador= 0;
        }else{             
            info=datos.filter((dat)=>dat.NombreFull.search(auxCadena.toUpperCase() ) !== -1) 
            //console.log(info);
            
            if (info.length<1)
            {
                info=datos.filter((dat)=>dat.Trabajo.search(auxCadena.toUpperCase() ) !== -1) 
            
            }
            contador= info.length;    
        }   
        
        return TablaPromo(info,contador);
    }
    function TablaPromo(datos,total) {
        let numRenglon=0;
        return(
            <TableContainer className={classes.container}>
                <Table>
                    <TableHead >    
                        <TableRow>                                        
                            <TableCell className={classes.titulos} ><span style={{fontSize:'1.25rem!important', paddingTop:'.25rem!important'}}>{total} </span></TableCell>                                                                                                
                            <TableCell className={classes.titulos} >Trabajo</TableCell>    
                            <TableCell className={classes.titulos} >Teléfono</TableCell>
                            <TableCell className={classes.titulos} >Celular</TableCell>
                            <TableCell className={classes.titulos} >Indep.</TableCell> 
                            <TableCell className={classes.titulos} >Afiliado</TableCell>                                                                       
                            <TableCell className={classes.titulos} >Email</TableCell>   
                            <TableCell className={classes.titulos} >Edad</TableCell>                          
                        </TableRow>                        
                    </TableHead>  
                    <TableBody>                                  
                        {datos.map((postulante) => {
                            let auxNombre="";
                            auxNombre=postulante.NombreFull;    
                            //console.log(auxNombre);
                            numRenglon++;                                                                                                            
                            return (                  
                                <TableRow selected={ seleccionado === postulante.Id}  className={classes.tableRow}  key={numRenglon} style={{height: '1em', }}
                                onClick={ () => {RenglonNum(postulante.Id,postulante)} }  classes={{ hover: classes.hover, selected: classes.selected }} 
                                  >                                                                                                                                       
                                    <TableCell style={{padding: 0+'rem'}}>
                                        {postulante.Id ===0?null:
                                         <span data-tip="Información del Afiliado">
                                            <a className="" /*onClick={llamdaModal2}*/> <img src={seleccionado === postulante.Id ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                        </span>                 }             
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} ><span>{postulante.Trabajo}</span></TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}> {postulante.Telefono}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}>{postulante.Celular}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}>{postulante.Independiente === 1?"Sí":(postulante.Independiente === 3?null:"No")}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}>{auxNombre}</TableCell>                                                                                                                                                                                     
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}>{postulante.Email==="@"?null:postulante.Email}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}>{postulante.Edad}</TableCell>
                                </TableRow>              
                                );                        
                            })
                        }                                                
                    </TableBody>
                </Table>
            </TableContainer>
        ); 
    }

    const {nombre} = promotor;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };
    //const datGeneral = datosJS&&registro? (<div className="pl-5"><DatosGeneral valor={registro}/> <Graficas valor={registro}/></div>):(null)
    const contenido = promotor? (filtro()):(TablaPromo(datos,contador));
    //const contenido = (TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    //const modal2= mensaje2? (<ModalDatGen datGen={registro} mostrar={mensaje2} setMensaje={setMensaje2}/>):(null);      
    return (
        <div>
            <div>                        
                <div>
                    <div>            
                        <div className={classes.formControl}>
                                <TextField className={classes.buscador}
                                    name="nombre"                         
                                    placeholder="Afiliado"
                                    value={nombre}
                                    onChange={onChange}/>
                            </div>
                            <ReactTooltip place="left" type="success" effect="solid" />  
                        </div>		            
                        {llamada}
                    </div>
                </div>
                {/*modal2*/}
        </div>
      );
}
 
export default BusqTablaPostulante;