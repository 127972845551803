import React, {useState, useEffect } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import {ListItemIcon,ListItemText,Typography} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import InfoIcon from '@material-ui/icons/Info';
import CreateIcon from '@material-ui/icons/Create';
import MenuContext from '../generales/MenuContext'
import Bitacora from "./Bitacora";
import NuevaAlerta from './NuevaAlertaBitacora'
import ModalMapa from './ModalMapa'
import moment from 'moment';
export default function MenuAlerta ({handleClose,valorCursor,alertaSeleccionada}){
    const [modalNvaBitacora, setModalNvaBitacora] = useState(false)
    const [modalBitacora,setModalBitacora]= useState(false);  
    
    const [infoMapaMod, setInfoMapaMod] = useState(false);
    
    const [tituloInfo, setTituloInfo] = useState("")
    const [subtInfo, setSubtInfo] = useState("")
    const [tituloNuevo, setTituloNuevo] = useState("")
    const [tituloMapa,setTituloMapa] = useState("")
    useEffect(()=>{
        console.log(alertaSeleccionada);
    },[])

    const modalBit = modalBitacora ? <Bitacora seleccionado={alertaSeleccionada} titulo={tituloInfo} subtitulo={subtInfo}
                                    setModalAbierto={setModalBitacora} modalAbierto={modalBitacora}/> : null;

    const modalNuevo = modalNvaBitacora ? <NuevaAlerta registro={alertaSeleccionada} titulo={tituloNuevo} subtitulo={subtInfo}
                                          setModalAbierto={setModalNvaBitacora} modalAbierto={modalNvaBitacora}/> : null;

    const modalMap = infoMapaMod ? <ModalMapa seleccionado={alertaSeleccionada} titulo={tituloMapa} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

      function abrirModalMapa(){
        let fecha = moment.utc(alertaSeleccionada.FchReg).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
        ? "" : moment.utc(alertaSeleccionada.FchReg).format('DD/MM/YYYY HH:mm:ss')
        let titulo=`${alertaSeleccionada.TipoNom} ${alertaSeleccionada.Id}`;
        setTituloMapa(titulo)
        setSubtInfo(fecha)
        setInfoMapaMod(!infoMapaMod)
      
      }
    function abrirModaBitacora(){
        let fecha = moment.utc(alertaSeleccionada.FchReg).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
        ? "" : moment.utc(alertaSeleccionada.FchReg).format('DD/MM/YYYY HH:mm:ss')
        let titulo=`${alertaSeleccionada.TipoNom} ${alertaSeleccionada.Id}`;
        setTituloInfo(titulo)
        setSubtInfo(fecha)
        setModalBitacora(!modalBitacora)
      }

      function abrirModalNuevo(){
        let date = new Date();
        let titulo=`Actualizar Estatus de Bitácora ${alertaSeleccionada.TipoNom} ${alertaSeleccionada.Id}`;
        setTituloNuevo(titulo);
        setSubtInfo(moment.utc(date).local(true).format("DD/MM/YYYY HH:mm"))
        setModalNvaBitacora(!modalNvaBitacora)
      }

    const StyledMenuItem = withStyles((theme) => ({
        root: {
          '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.common.white,
            },
          },
        },
      }))(MenuItem);
    return (        
     
        <MenuContext valorCursor={valorCursor} handleClose={handleClose}>
            
            <Typography style={{textAlign:'center',fontWeight:550, marginLeft:15, marginRight:15}} color="primary">
                Menú de {alertaSeleccionada.TipoNom + " " + alertaSeleccionada.Id}
            </Typography>
            <br/>                        
            <StyledMenuItem onClick={abrirModaBitacora}>
                <InfoIcon fontSize="small"  color="primary" style={{marginRight:'.5em'}}/>
                <ListItemText primary="Bitácora" />
            </StyledMenuItem>   
            <StyledMenuItem onClick={abrirModalNuevo}>
                <CreateIcon fontSize="small"color="primary" style={{marginRight:'.5em'}}/>
                <ListItemText primary="Actualizar Bitácora" />
            </StyledMenuItem> 
            <StyledMenuItem onClick={abrirModalMapa}>
                <PlaceIcon fontSize="small"color="primary" style={{marginRight:'.5em'}}/>
                <ListItemText primary="Mapa" />
            </StyledMenuItem>   
            {modalBit}
            {modalNuevo}    
            {modalMap}                   
        </MenuContext>      
    )
}