import React , {useState, useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Select,InputLabel, MenuItem, Grid,Box} from '@material-ui/core'

import {authUser} from "../funciones/AuthUser";
import {AuthContext} from "../context/AuthContext";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      //width: '19ch',            
    }, 
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  },

}));

export default function NuevoProcedimientoCliente({titulo,modalAbierto,setModalAbierto,IdCli,setActualizar,actualizar}){
  const classes = useStyles();
  const [nvoProcedimiento, setNvoProcedimiento] = useState({Cliente:IdCli,Procedimiento:0})
  const [espera,setEspera]=useState(false)
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
  const [aviso, setAviso] = useState(false);
  //const [listClientes, setListClientes] = useState([]);;
  const [listProcedimientos, setListProcedimientos] = useState([]);
  const {Cliente, Procedimiento} = nvoProcedimiento;
  const historial = useHistory();  
  const {guardarUsuario}=React.useContext(AuthContext);
  useEffect(()=>{
    async function getListadoProcedimientos(){
      setEspera(true);
      //setBtnDeshabilitado(true);
      let auxU = process.env.REACT_APP_LINK + `/procedimiento-list`;
      let token = localStorage.getItem("token20");
      let dataInfo = qs.stringify({
        idDispositivo: "987ygubhjnjik",
      });
      let config = {
        url: auxU,
        method: "POST",
        data: dataInfo,
        headers: {
          "access-token": token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        cancelToken: source.token,
      };
      let aux = {};
      await axios(config)
      .then(async (response) => {
        aux = response.data;
        if (aux[0].Id>-1) {
          setListProcedimientos(aux); 
        }
      })
      .catch(function (error) {
        console.log(`Error: ${error}`);
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log("Token inválido");
            localStorage.clear();                      
            historial.push("/"); 
            guardarUsuario([])                                  
          } 
        }
      });
      setEspera(false); 
    }
    async function getListadoClientes(){
      let auxU = process.env.REACT_APP_LINK + `/cliente-list`;
      let token = localStorage.getItem("token20");
      let usuario = localStorage.getItem("UsuId");
      let dataInfo = qs.stringify({
        Usuario: usuario,
        idDispositivo: "987yugbhcdsscs",
      });
      let config = {
        url: auxU,
        method: "POST",
        data: dataInfo,
        headers: {
          "access-token": token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        cancelToken: source.token,
      };
      let aux = {};
      await axios(config)
      .then(async (response) => {
        aux = response.data;
        if (aux[0].Id>-1) {
          var auxClientes = aux.filter(function (val) {
            return val.TipoId === 1;
          });
          if (auxClientes.length>0){
            auxClientes.splice(0,0,{
              Id:0, PromotorNom:"Seleccione"
            });
            //setListClientes(auxClientes); 
            console.log(auxClientes);
          }
        }
      })
      .catch(function (error) {
        console.log(`Error: ${error}`);
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log("Token inválido");
            localStorage.clear();                      
            historial.push("/"); 
            guardarUsuario([])                                  
          } 
        }
      });
    }
    const autenticado = authUser();
      if(!autenticado){
        localStorage.clear();
        historial.push('/');
        guardarUsuario([]);    
      }else{
        getListadoProcedimientos();
        getListadoClientes();
      }
  },[])

 /*  const onChangeCliente = (e) => {
    setNvoProcedimiento({
      ...nvoProcedimiento,
      Cliente: e.target.value,
    });
  } */

  const onChangeProcedimiento = (e) => {
    setNvoProcedimiento({
      ...nvoProcedimiento,
      Procedimiento: e.target.value,
    });
  }

    function guardarDatos(){
      if(Cliente>0 && Procedimiento>0){
        registrarProc();
      }else{
        if(Cliente===0){
          setMensaje("Seleccione un Cliente Válido.");
          setAviso(true);
          setTipoAdvertencia("error");
        }
        if(Procedimiento ===0){
          setMensaje("Seleccione un Procedimiento Válido.");
          setAviso(true);
          setTipoAdvertencia("error");
        }
      }
    }

   async function registrarProc(){
    setEspera(true);
    setBtnDeshabilitado(true);
    let auxU=process.env.REACT_APP_LINK +`/cliente-procedimiento-nuevo`;                               
    let token =localStorage.getItem('token20'); 
    let usuario = localStorage.getItem('UsuId');
    let dataInfo = qs.stringify({
        Cliente: Cliente,
        Usuario: usuario,
        Procedimiento: Procedimiento,
        idDispositivo: "987yugbhcdsscs",
    });
    let config = {
        url:auxU ,
        method: 'POST',
        data: dataInfo,
        headers: {
            'access-token' : token,
            'Content-Type': "application/x-www-form-urlencoded"
        },
        cancelToken: source.token,
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {              
      aux =  response.data; 
      if (aux.respuesta===1) 
      { setActualizar(!actualizar)           
        let mensaje="Se registro correctamente el Procedimiento"
        setTipoAdvertencia("success")      
        setMensaje(mensaje);
        setAviso(true); 
        setNvoProcedimiento({
          ...nvoProcedimiento,
          Cliente: IdCli,
          Procedimiento: 0
        })
         
      }                                            
    })
    .catch(function (error) {                  
        console.log(`Error: ${error}`);              
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
            console.log('datos incorrectos' );  
        }else
        {
            if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
            {
            console.log('loguearse de nuevo');
            localStorage.clear();    
            guardarUsuario([])                     
            historial.push("/");                              
            } 
        }
    });    
    setEspera(false);   
   }

  return(
    <Modal
      titulo={titulo}    modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} btnDeshabilitado={btnDeshabilitado}
      setAviso={setAviso} tamanio={'md'}cargando={espera}        
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>
      <Grid container spacing={2}>
        
        <Grid item style={{marginLeft:'25%'}} >
          <Box style={{display:'flex', alignItems:'center'}}>
            {listProcedimientos.length>0 ?
              <FormControl className={classes.formControl} style={{width:16+'rem'}}>
              <InputLabel htmlFor="Procedimiento">Procedimiento</InputLabel>
              <Select id="Procedimiento" name="Procedimiento" value={Procedimiento} onChange={onChangeProcedimiento} >
                    {listProcedimientos.map((procedimiento, index) => (
                      <MenuItem value={procedimiento.Id} key={procedimiento.Id}>
                        <em>{procedimiento.Descr}</em>
                      </MenuItem>
                    ))}
              </Select>
              </FormControl> :null}
              </Box>
        </Grid>
      </Grid>
      </form>
  </Modal>
  )
}