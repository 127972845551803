import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Button,InputLabel, MenuItem, TextField,Select} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'20em'
    },
    formSelect: {
      width:'8em'
    },
    formSelectElemento:{
        width:'15em'
    },
    formObs:{
        marginTop:'1.7em',
        width:'30em',
        height:'6em'
    },
  }));


export default function NuevaAlertaBitacora({registro,modalAbierto,setModalAbierto,titulo,subtitulo}){
    const classes = useStyles();
    const source = axios.CancelToken.source();    
    const [listaStatus, setListaStatus] = useState([]);
    const [listaElementos, setListaElementos] = useState([])
    const [elemento, setElemento] = useState(registro.SegElemId)
    const [espera, setEspera] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [status, setStatus] = useState(registro.StatusId);    
    const [direccion,setDireccion] = useState("");
    const [observacion,setObservacion] = useState(registro.Observ);
    const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/

    useEffect(()=>{
        async function getStatus(){
            let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-status`;   
            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        
                        setListaStatus(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }

        async function getElementos(){
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-elemento-list`;   
            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        aux.sort(function (a, b) { return a.Id - b.Id; }); 
                        var auxFiltrado = aux.filter(function (val) {
                            return val.Id !== 0;
                          });
                          auxFiltrado.splice(0,0,{
                            Id: 0,
                            Nombre: "Sin Asignar",
                            Paterno:"",
                            Materno:""
                          });
                        setListaElementos(auxFiltrado)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }
        getStatus()
        getElementos()
    },[]);


    function onChangeStatus(e){
        setStatus(e.target.value)
    }

    function onChangeElemento(e){
        setElemento(e.target.value)
    }

    function onChanageDireccion(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value===''){
            setDireccion(e.target.value);
        }
    }
    function onChangeObservacion(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value===''){
            setObservacion(e.target.value);
        }
    }

    function verificarObservacion(ob){
        let validado=false;
        if(ob && ob!==" "){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Ingrese Observación")
            setAviso(true)
            validado=false;
        }
        return validado;
    }


    function verificarElemento(ele){
        let validado=false;
        if(parseInt(ele)!==0){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione un elemento válido")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function handleSubmition(){
        if(parseInt(status)!==0){
            if(verificarElemento(elemento)&&verificarObservacion(observacion)){
                registrar()
            }
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione un Status válido")
            setAviso(true)
        }
    }

    async function registrar(){
        setEspera(true);
        setBtnDeshabilitado(true);

        let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-bitacora-nuevo`;   
            let dataInfo = qs.stringify({     
                'Alerta':registro.Id,
                'Usuario':1,   
                'Status':status,
                'Publico':registro.PublicoId,
                'Elemento': elemento,
                'Lat': registro.Lat,
                'Lon':registro.Lon,
                'Direcc':registro.Direcc,
                'Observ':observacion,
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux.respuesta===1){
                        setObservacion("")
                        setDireccion("");
                        setTipoAdvertencia("success")
                        setMensaje("Nuevo elemento de bitácora agregado con éxito")
                        setAviso(true)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        setEspera(false)
        setBtnDeshabilitado(false)

    }



    return (
        <Modal
        titulo={titulo}
        subtitulo={subtitulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmition}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'l'}
        cargando={espera}>
            <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                {listaStatus.length!=0 ? 
                <Grid item>
                <FormControl className={classes.formSelect}>
                    <InputLabel id="tipo">Status</InputLabel>
                    <Select className={classes.formSelect}
                      label="status" id="status" name="status"
                      value={status} onChange={onChangeStatus}
                    >
                      {listaStatus.map((stat, index) => (
                        <MenuItem value={stat.Id} key={stat.Id}>
                          <em>{stat.Nom}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
            </Grid>
            <Grid container spacing={1}>
                {listaElementos.length!=0 ? 
                <Grid item>
                <FormControl className={classes.formSelectElemento}>
                    <InputLabel id="tipo">Elemento</InputLabel>
                    <Select className={classes.formSelectElemento}
                      label="elemento" id="elemento" name="elemento"
                      value={elemento} onChange={onChangeElemento}
                    >
                      {listaElementos.map((ele, index) => (
                        <MenuItem value={ele.Id} key={ele.Id}>
                          <em>{ele.Nombre+" "+ele.Paterno+" "+ele.Materno}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
            </Grid>
            {/*
            <Grid container spacing={1}>
                <Grid item>
                <FormControl className={classes.formAnotacion}>
                    <TextField required label="Dirección"
                                  value={direccion} onChange={onChanageDireccion}id={'direccion'}/> 
                  </FormControl>
                </Grid>
            </Grid>
            */}
            <textarea className={classes.formObs} placeholder="Observación"
              value={observacion} onChange={onChangeObservacion} id={'observacion'}
              multiline rows={2} maxRows={4}/>
            </form>
        </Modal>
    )
}