import React,{useState,useEffect} from 'react';
import {Box,LinearProgress} from "@material-ui/core";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import CardTotales  from './CardsTotales';
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import Graficas from './Graficas';
export default function RedEmpInicio () {
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [datos,setDatos] = useState("");
    let history = useHistory();
    const [vacio,setVacio] = useState(true);               
    const [ListEdad,setListEdad] = useState("");  
    const [espera,setEspera]=React.useState(false);

    useEffect(()=>{ 
        const usu= localStorage.getItem('UsuarioNom');
        let nivelUsuario =localStorage.getItem('Tipo') ;
        let idPromot =localStorage.getItem('idPromot') ;
        let token =localStorage.getItem('token20') ; 

        const source = axios.CancelToken.source();
        async function conectarJSon()  {
            setEspera(true)
            let auxU=process.env.REACT_APP_LINKRED2+`/afiliado-lista-profesion`;               
            let dataInfo = qs.stringify({                
                'idDispositivo': 'qdecaxsascasxaxsa' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
           
            await axios(config)
            .then(function (response) {
           
                aux=  response.data;
           
                if (aux.resultado) {                    
                    //console.log(aux.resultado);
                    let barrido= aux.resultado;            
                    if (nivelUsuario==='5') 
                    {                        
                        //console.log("el promotor "+idPromot);                    
                        let info=barrido.filter((dat)=>dat.PromotId.toString()=== idPromot)
                        //console.log(info);
                        setDatos(info);
                    }
                    else
                    {   
                        setDatos(aux.resultado);
                        console.log(aux.resultado);
                    }
                }else{
                    setVacio(false)
                }
            })
            .catch(function (error) {
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                                       
                        history.push("/");
                    } 
                }
            });
            setEspera(false)
        };
        async function llenaSeccion () 
        { 
            setEspera(true)
        let auxU=process.env.REACT_APP_LINKRED2+`/afiliado-lista-edad`;                  
             
        let dataInfo = qs.stringify({            
            'idDispositivo': 'csdq21weddewde' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};
        await axios(config)
        .then(function (response) {
            aux=  response.data;                                
            if (aux.resultado) 
            {   setListEdad(aux.resultado);    
                console.log(aux.resultado);
            }                     
        })
        .catch(function (error) {
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                                
                    history.push("/");
                } 
            }
        }); 
        setEspera(false)
        }

        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push('/');
            guardarUsuario([]);  
        }else{
            if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nivelUsuario});                                         
                    
                }
            document.title = 'Red Empleo Inicio'                     
            guardarGenerales({...generales,Titulo:'Red Empleo Inicio'})    
            llenaSeccion();           
            conectarJSon();
        }
        
        return ()=> {
            source.cancel();
        }

},[]);

    const content =ListEdad&&datos ? (<div ><CardTotales Tipo={datos} ListEdad={ListEdad} /> 
                    <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                            <Graficas Tipo={datos}  ListEdad={ListEdad}/>
                    </div>
                                                </div> ) : ( null);

    const esperaCont = !espera ? content : (<Box pt={3}><LinearProgress/> </Box>)
    
    return (<Box style={{paddingTop:1+'rem'}}>
            
                {esperaCont}                      
        </Box> 
         );
}