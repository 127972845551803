import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import Tema from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
//import TablaCliente from './componentes/clientes/TablaClientes'
import { ToastContainer } from 'react-toastify';
import PanelProcedimiento from './componentes/procedimientos/PanelProcedimiento'
import RedEmpInicio from './componentes/redEmpInicio/RedEmpInicio'
import RedEmpBuscar from './componentes/redEmpBuscar/Buscar'
import RedEmpTrabajo from './componentes/redEmpTrabajo/BusqTrabajoInicio'
import ListadoUsu from './componentes/usuario/UsuarioInicio'
import NotificacionInicio from './componentes/notificacion/NotificacionInicio';
function App() {
  return (
    <React.Fragment >
      <AuthProvider>
        <GeneralesProvider>
          <ClienteProvider>
          <Router>
            <ThemeProvider theme={Tema}>
              <Contenedor>              
                <Switch>
                  <Route exact path="/" component={Login}  />          
                  {/* <Route exact path="/lista-usuario" component={TablaCliente}  />   */}
                  <Route exact path="/lista-usuario" component={ListadoUsu}  />   
                  {/* <Route exact path="/subir-archivos" component={ArchivosInicio}  />    */}   
                  <Route exact path="/cliente-procedimiento" component={PanelProcedimiento}  />     
                  <Route exact path="/red-emp-inicio" component={RedEmpInicio}  />  
                  <Route exact path="/red-emp-busq" component={RedEmpBuscar}  />     
                  <Route exact path="/red-emp-trabajo" component={RedEmpTrabajo}  />  
                  <Route exact path="/bitacora" component={NotificacionInicio}  /> 
                </Switch>              
              </Contenedor>
            </ThemeProvider>
            <ToastContainer autoClose={5000} closeOnClick/>
          </Router>
          </ClienteProvider>
        </GeneralesProvider>
      </AuthProvider>
    </React.Fragment>
  );
}

export default App;