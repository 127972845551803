import React , {useState, useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Select,InputLabel, MenuItem, TextField,Card} from '@material-ui/core'
import {AuthContext} from "../context/AuthContext";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import moment from "moment";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '20ch',
    }, 
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
  formEncabezado:{
    marginTop:'1.7em',  
  },
  formSelect: {
    width:'12em'
  },
  tarjetaStatus:{
    height:'15'
  },
  formAnotacion: {
    width:'15em'
  },
  colorPendiente:{
    fontSize:14, marginTop:'1.2em', 
    color:'white', fontWeight:'bold',
    backgroundColor: '#F6AC23',
    display:'flex',
    flexDirection:'column',
    width:'6em',
    height:'2.5em'
  },
  colorRevisar:{
    fontSize:14, marginTop:'1.2em', 
    color:'white', fontWeight:'bold',
    backgroundColor:'#0EA7E0',
    display:'flex',
    flexDirection:'column',
    width:'7em',
    height:'2.5em'
  },
  colorRechazado:{
    fontSize:14, marginTop:'1.2em',
    color:'white', fontWeight:'bold',
    backgroundColor:'red',
    display:'flex',
    flexDirection:'column',
    width:'7em',
    height:'2.5em'
  },
  colorAprobado:{
    fontSize:14, marginTop:'1.2em', marginLeft:'1.5em',
    color:'white', fontWeight:'bold',
    backgroundColor: '#049b4f',
    display:'flex',
    flexDirection:'column',
    width:'6em',
    height:'2.5em'
  },
}));

  export default function EditarArchivoStatus({titulo,modalAbierto,setModalAbierto, archivo, indice, arrayTotales, setArrayTotales, listaTipoStatus,
                                              setArchivoMenuSelec, setprogressTotales,progressTotales, procedSeleccionado, setSubiendo}){
    const classes = useStyles();
    const [archivoEdit, setArchivoEdit] = useState(archivo)
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [listaStatus, setListaStatus] = useState([]);
    const [aviso, setAviso] = useState(false);
    const [anotacion, setAnotacion] = useState("");
    const [status, setStatus] = useState(archivo.ProcesoStatusId);
    const [nombreStatus, setNombreStatus] = useState("");
    const [colorStatus, setColorStatus] = useState("");
    const historial = useHistory();
    
    const {guardarUsuario}=React.useContext(AuthContext);

    useEffect(()=>{
      setListaStatus(listaTipoStatus)
        switch(archivo.ProcesoStatusId){
          case 0:
            setColorStatus(classes.colorPendiente);
            setNombreStatus("Pendiente");
            break;
          case 1:
            setColorStatus(classes.colorRevisar);
            setNombreStatus("Por Revisar");
            break;
          case 2:
            setColorStatus(classes.colorRechazado);
            setNombreStatus("Rechazado");
            break;
          case 3:
            setColorStatus(classes.colorAprobado);
            setNombreStatus("Aprobado");
            break;
        }
        setArchivoEdit(archivo)
   },[archivo.ProcesoStatusId])


    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        setMensaje("¿Está seguro de Actualizar el Estatus?");
        setAviso(true);
        setTipoAdvertencia("warning");
        setAnotacion("");
    };

    const onChangeAnotacion = (event) =>{
        setAnotacion(event.target.value);
    }

    const handleSubmission = ()=>{
        if(status>0){
          if(status===archivoEdit.ProcesoStatusId){
              setMensaje("Seleccione un Estatus Diferente.");
              setAviso(true);
              setTipoAdvertencia("error");
          }else{
              if(status!==2){
                  actualizarArchivo();
              }
              else {
                if(status ===2 && anotacion && anotacion!=" "){
                  actualizarArchivo();
              }else{
                setMensaje("Ingrese una Anotación.");
                setAviso(true);
                setTipoAdvertencia("error");
            }
            }
          }
        }else{
          setMensaje("Seleccione una Opción Válida.");
          setAviso(true);
          setTipoAdvertencia("error");
        }
    };

    async function actualizarArchivo()  {     
        setEspera(true);
        setSubiendo(true);
        setBtnDeshabilitado(true);
        let auxU=process.env.REACT_APP_LINK +`/procedimiento-documento-status`;                               
        let token =localStorage.getItem('token20'); 
        let usuario = localStorage.getItem('UsuId');
        let dataInfo = qs.stringify({
            Proceso: procedSeleccionado.Id,
            Documento: archivoEdit.CliDocId,
            Status: status,
            Anotacion: anotacion,
            Usuario: usuario,
            idDispositivo: "987yugbhcdsscs",
        });
        let config = {
            url:auxU ,
            method: 'POST',
            data: dataInfo,
            headers: {
                'access-token' : token,
                'Content-Type': "application/x-www-form-urlencoded"
            },
            cancelToken: source.token,
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux =  response.data; 
          if (aux.respuesta===1) 
          {                
            let mensaje="Se editó correctamente el Estatus del Archivo"
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true); 
            setAnotacion("");
            let arrAux = arrayTotales;
            var fechaNueva = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]');
            let nuevoAux = 
            {
                CliDocId: archivo.CliDocId,
                ProcesoDocId: archivo.ProcesoDocId,
                ProcesoStatusId: status,
                Fecha: archivo.Fecha,
                FSolicita: archivo.FSolicita,
                FMaxima: archivo.FMaxima,
                FEnvio: archivo.FEnvio,
                FRechazo: archivo.FRechazo,
                FAcepta: archivo.FAcepta,
                FActualiza: fechaNueva,
                Direcctorio: archivo.Direcctorio,
                Archivo: archivo.Archivo,
                Extension: archivo.Extension,
                Size: archivo.Size,
                Anotacion: archivo.Anotacion,
                Rechazo: archivo.Rechazo,
                UsuarioId: archivo.UsuarioId,
                UsuarioNom: archivo.UsuarioNom,
                DocTipoId: archivo.DocTipoId,
                DocAlias: archivo.DocAlias,
                DocDescr: archivo.DocDescr,
                DocNota: archivo.DocNota,
                DocExt: archivo.DocExt
            }
            arrAux.splice(indice,1,nuevoAux);
            setArchivoMenuSelec(nuevoAux);
            let contAux = progressTotales;
            if(status===2){
              contAux--;
                setprogressTotales(contAux)
            }else{
              if(archivoEdit.ProcesoStatusId==0 && status>0){
                contAux++;
                setprogressTotales(contAux)
              }
            }
            setArrayTotales(arrAux);
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();    
                guardarUsuario([])                     
                historial.push("/");                              
                } 
            }
        });    
        setEspera(false);   
        setSubiendo(false);  
    };

    return(
      <Modal
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmission}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'xl'}
        cargando={espera}        
    >
        <form className={classes.root} noValidate autoComplete="off" style={{display:'flex', flexDirection:'row'}} >
              {nombreStatus.length>0 ?
                <Card className={colorStatus}>
                    <span style={{marginLeft:'.5em', marginTop:'.5em'}}>{nombreStatus}</span>
                </Card> : null}
         
              { listaStatus.length !== 0 ?
                <FormControl className={classes.formSelect}>
                    <InputLabel id={'status'}>Estatus</InputLabel>
                    <Select
                    label={'Estatus'} id={'status'} name={'status'} value={status} onChange={handleChangeStatus}
                    >
                    {listaStatus.map((stat, index) => (
                        <MenuItem value={stat.Id} key={stat.Id}>
                        <em>{stat.Descr}</em> 
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>:null}
             {status==2 ?
                <FormControl className={classes.formAnotacion}>
                <TextField required label="Anotación" name="anotacion"
                                value={anotacion} onChange={onChangeAnotacion} id={'anotacion'}/> 
                </FormControl> :null}
        </form>
    </Modal>
    )
  }