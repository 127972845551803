import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,TextField,Tooltip,FormControl,//Select as MaterialSelect ,
        FormLabel,FormControlLabel,RadioGroup,Radio,
        InputLabel,Select,MenuItem} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrar';
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const EditarUsuario = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,usrSelecc}) => {
    let history = useHistory();
    const classes = useStyles();
 
    const[nvoCliente,setNvoCliente]=React.useState({Nss:'',Nombre:'',Paterno:'',Materno:'',
                                                Curp:'',RFC:'',FNacimien:'Mon Oct 25 1900 16:42:31 GMT-0600 (hora de verano central)',
                                                EstadoCivil:'',IdentificacionOfi:'',NumIdentifi:'',Nacionalidad:'',
                                                Email:'',  Genero:'',Pass:'',Calle:'', CInt:'',CExt:'',Tel:'',Cel:'',
                                                Face:'',Insta:'',Twit:'',Colonia:'',Seccion:'',Obser:'', IdUsr:0
                                            });                                                
    
    //const [listMunicipio, setListMunicipio] = React.useState([]);
    //const [listCodigoPost, setListCodigoPost] = React.useState([]);
    //const [listColonia, setListColonia] = React.useState([]);
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [aviso, setAviso] = React.useState(false);    
    const[auxPass,setAuxPass]= React.useState('123456');      
    const [listColonia, setListColonia] = React.useState([]);  
    const [listSeccion, setListSeccion] = React.useState([]);      
    const [listPermiso, setListPermiso] = React.useState([]);                                          
    const{  Nss,Nombre,Paterno,Materno,Curp,RFC,FNacimien,
            EstadoCivil,IdentificacionOfi,NumIdentifi,Nacionalidad,
            Email, Genero,Pass,Calle,Obser,CInt,CExt,Tel,Cel,Face,
            Insta,Twit,Colonia,Seccion,IdUsr}=nvoCliente

    let colorCheckR='#0EA7E0'
    const source = axios.CancelToken.source();     
    React.useEffect(()=>{
        setNvoCliente({...nvoCliente,
            IdUsr: usrSelecc.Id ,Nombre: usrSelecc.Nombre ,Paterno: usrSelecc.Paterno ,Materno: usrSelecc.Materno,
            Curp: usrSelecc.Curp ,RFC: usrSelecc.Rfc ,FNacimien: moment().format(usrSelecc.Fnacimiento),
            NumIdentifi: usrSelecc.Ine ,Email: usrSelecc.Email ,Genero: usrSelecc.Genero ,Pass: '123456'
            ,Calle: usrSelecc.Calle ,Obser: usrSelecc.Obsrv ,CInt: usrSelecc.Inte ,CExt: usrSelecc.Exte ,
            Tel: usrSelecc.Tel , Cel: usrSelecc.Cel ,Face: usrSelecc.Facebook , Insta: usrSelecc.Twitter ,
            Twit:usrSelecc.Twitter ,Colonia: usrSelecc.ColoniaId ,Seccion: usrSelecc.SeccionId
        }) 
        /* async function conectarJSon()  {           
            setEspera(true)       
            let auxU=process.env.REACT_APP_LINKTEZO +`/usrpublico-list-permiso`;       
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({    
                'usuario':1,                              
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;    
                        
                if (aux.length !== 0) 
                {   //console.log(aux);     
                    setListPermiso(aux.PermisoId)
                }else{        
                }                                            
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });                
            setEspera(false)     
        }; */
        async function llamadaPas()  {           
            setEspera(true)       
            let auxU=process.env.REACT_APP_LINKTEZO +`/usrpublico-pass`;       
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({    
                'usuario':usrSelecc.Id,        
                'usuarioSystem': 1 ,                    
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                    
                if (aux.length !== 0) 
                {   console.log(aux[0].keyPass);
                    setAuxPass(aux[0].keyPass)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });                
            setEspera(false)     
        };
        async function llamadaColonia()  {           
            setEspera(true)       
            let auxU=process.env.REACT_APP_LINKTEZO +`/lugar-colonia`;       
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({    
                'municipio':764,
                'usuario':1  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                    
                if (aux.length !== 0) 
                {    
                    setListColonia(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });                
            setEspera(false)     
        };
        async function llamadaSeccion()  {           
            setEspera(true)       
            let auxU=process.env.REACT_APP_LINKTEZO +`/lugar-seccion`;       
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({    
                'municipio':764,
                'usuario':1  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                    
                if (aux.length !== 0) 
                {    
                    setListSeccion(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });                
            setEspera(false)     
        };
        async function llamadaPermiso()  {           
            setEspera(true)       
            let auxU=process.env.REACT_APP_LINKTEZO +`/usrpublico-list-permiso`;       
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({    
                'usuario':usrSelecc.Id,                            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                    
                if (aux.length !== 0) 
                {   let cadena=''; 
                    aux.map((permiso)=>{
                        cadena=cadena+permiso.PermisoId+','
                    })
                    console.log(cadena);
                    console.log(cadena.slice(0,cadena.length-1));
                    setListPermiso(cadena.slice(0,cadena.length-1))                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
            });                
            setEspera(false)     
        };
       // conectarJSon()
        llamadaPas()
        llamadaColonia()
        llamadaSeccion()
        llamadaPermiso()
    },[])
    const verificaINE = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( ( (campo.length===0 || campo.trim() === null || campo.length<18) )
        ) 
        {   respuesta=true;        
            console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  ml={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.5+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"    
              minDate="1900-03-14" maxDate={"2003-10-26"}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }
          
   
    const guardarFNaci = auxfecha =>
    {
        setNvoCliente({
            ...nvoCliente,
            FNacimien : auxfecha  
        })
    };
    
    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[.%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		
	};         
    const onChangeNum = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		
	}; 
    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		    

	}; 
    const onChangeSelectColonia = e =>
	{   e.preventDefault();
        setNvoCliente({
            ...nvoCliente, 
            [e.target.name] : e.target.value  
        })                       
	};  
    const onChangeSelect = e =>
	{   e.preventDefault();
        setNvoCliente({
            ...nvoCliente, 
            [e.target.name] : e.target.value  
        })                       
	};      
        
    const guardaCheckRad = e =>
    {  //console.log(e);
        setNvoCliente({
            ...nvoCliente,
            [e.target.name] : e.target.value  
        })  

    };     
    const guardaCheckRad2 = e =>
    {  
        setNvoCliente({
            ...nvoCliente,
            Nacionalidad : e.target.value  
        })  

    };   
   
    async function llamadaNvoCliente( auxFechaNacimient,edad)  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINKTEZO +`/usrpublico-editar`;                               
        let token =localStorage.getItem('token20') ;               
        //let usuId =localStorage.getItem('UsuId') ;                                      
        let dataInfo = qs.stringify({    
            'IdUsr':IdUsr,                                                   
            'IdUsrSyst':1,
            'Seccion':Seccion,
            'Colonia':Colonia,
            'Status':1,
            'Nombre':Nombre,
            'Paterno':Paterno,
            'Materno':Materno,
            'Pass':auxPass,
            'Email':Email,
            'Rfc':RFC.length===0?' ':RFC,
            'Curp':Curp,
            'Ine':NumIdentifi,
            'Genero':Genero,
            'Nacimiento':auxFechaNacimient,//1997-10-11T00:00:00,
            'Edad':edad,
            'Calle':Calle,
            'Int':CInt,
            'Ext':CExt,
            'Tel':Tel, 
            'Cel':Cel,
            'Face':Face.trim() === '' ? Email :Face,
            'Twit':Insta.trim() === '' ? Email :Insta,
            'Inst':Twit.trim() === '' ? Email :Twit,
            'Obsrv':' ',
            'Permisos':listPermiso,//'-2,-1,1,2,3,4,5',
            'idDispositivo':'451224452121'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux=  response.data;                                          
            console.log(aux)
          if (aux.length!==0) 
          {                
            //setNvoRegistro(aux[0]);  
            setActualizar( !actualizar)              
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el  Usuario"
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                    ...nvoCliente, //copiamos el nvocliente
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};  

    const onChangeContra = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setAuxPass(e.target.value)
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};  

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
       //console.log(campo);   
        if( (typeof campo !== 'number' && typeof campo === 'string' 
             && (campo.trim() === '' || campo.trim() === null) )
            )        
        {   respuesta=true;                    
            mandaAlerta('No se lleno el campo  '+descripcion ,"warning")                        
        }
        return(respuesta);
    }
    const verificaNulosSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if(campo === '' || campo === null ||campo == 0 )
        {   respuesta=true;                             
            mandaAlerta('Debe seleccionar campo '+descripcion ,"warning")            
        }
        console.log(campo+descripcion);  
        return(respuesta);
    }
    
    const verificaCurp=(Curp)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (Curp.trim().length===18) 
        {
            let auxnom=Curp.substring(0,4);
            let auxfech=Curp.substring(4,10);
            let auxdesc=Curp.substring(10,16);
            let auxgen=Curp.substring(10,11);
            let auxnumer2=Curp.substring(16,18);
            if ( ( expCadena.test(auxnom) && !expNumero.test(auxnom) ) && ( expNumero.test(auxfech) && !expCadena.test(auxfech) ) 
             &&( !expNumero.test(auxdesc) && expCadena.test(auxdesc)) && ( expCadena.test(auxgen)&& !expNumero.test(auxgen) )
             &&(expNumero.test(auxnumer2) && !expCadena.test(auxnumer2) ) ) 
            {
                if (auxgen==='H'||auxgen==='M') 
                {    respuesta =true ;                    
                }   
                else
                {   mandaAlerta('Debe escribir un CURP valido ',"warning")
                }
            }
            else
            {   mandaAlerta('Debe escribir un CURP valido ',"warning")            
            }
        } 
        else 
        {    mandaAlerta('Campo CURP incompleto, debe escribir un CURP valido ',"warning")            
        }
        
        return(respuesta);
    }    
    
    const verificaNombre=(nombre,appat,apmat)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (!verificaNulos(nombre,'Nombre') && !verificaNulos(appat,'Apellido Paterno') && !verificaNulos(apmat,'Apellido Materno')  ) 
        {                 
            if( expCadena.test(nombre) && !expNumero.test(nombre) && nombre.length>=3 )
            {
                if(  expCadena.test(appat) && !expNumero.test(appat) && appat.length>=3)
                {
                    if ( expCadena.test(apmat) && !expNumero.test(apmat) && apmat.length>=3 ) 
                    {  
                        respuesta=true;                     
                    }else
                    {   mandaAlerta('Debe escribir un Apellido Materno  valido',"warning")                        
                    }
                }
                else
                {   mandaAlerta('Debe escribir un Apellido Paterno  valido ',"warning")                    
                }
            }
            else
            {   mandaAlerta('Debe escribir un Nombre  valido ',"warning")                
            }    
        }    
        return(respuesta);
    }
    
    const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true; 
            mandaAlerta('Campo Email vacio, debe llenar el campo ',"warning")                 
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   mandaAlerta('Debe escribir un Correo valido  ',"warning")                     
            }            
        }
        return(respuesta);
    }  
 
    function valFechNacimiento( ) {
        let respuesta=false
        if (//FNacimien!=='Mon Oct 25 1900 16:42:31 GMT-0600 (hora de verano central)'
            moment( FNacimien).format('YYYY-MM-DD') !== '1900-10-25'   ) 
        {
            respuesta=true
        }
        else
        {
            mandaAlerta('Seleccionar Fecha de Nacimiento',"warning") 
        }
        return respuesta
    }
        
    function validaRFC() {
        let respuesta=false
        if (!verificaNulos(RFC,'RFC') ) 
        {
            if (RFC.trim().length >= 12) 
            {   respuesta=true            
            }   
            else
            {   mandaAlerta('Campo RFC incompleto , debe escribir un RFC valido ',"warning")                     
            }
        }
        return respuesta
    }   

    const veriNuloNum = (campo,descripcion)=>{
        let respuesta =false; 
        console.log(descripcion+ ' '+campo); 
        console.log(campo);                                 
        if((campo==='0'||campo===0 )|| campo === null ||campo === ""|| campo === " ")
        {   respuesta=true;                    
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
    function calcularEdad(fecha) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
    
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
    
        return edad;
    }

    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }

    function valDireccion() {
        let respuesta=false
        if ( !verificaNulos(Calle,'Calle') ) 
        {            
            if (Calle.length>=4 ) 
            {                
                respuesta=true                    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la Calle',"warning") 
                mandaAlerta('Campo Calle incompleto, debe escribir una Calle valida',"warning") 
            }
        }        
        return respuesta;
    }
    function validaVariable(auxCampo,auxMensaje,auxTamanio) {
        let respuesta=false
        console.log("antes de tronar");
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {            
            if ((typeof(auxCampo) === 'number'&& auxCampo.toString().length>=auxTamanio )
            ||( typeof(auxCampo) === 'string'&& auxCampo.length>=auxTamanio )
            ) 
            {                
                respuesta=true                    
            }else
            {
                //mandaAlerta('Campo '+auxMensaje +' incompleto, minimo '+ auxTamanio+' caracteres',"warning") 
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
    function verificaUsu() {
        let respuesta=false
        if ( !verificaNulos(Email,'Usuario') ) 
        {            
            if (Email.length>=4 ) 
            {                
                respuesta=true                    
            }else
            { 
                mandaAlerta('Campo Usuario incompleto, minimo 4 caracteres , debe escribir un Usuario valido',"warning") 
            }
        }        
        return respuesta;
    }
    function guardarDatos(e)
    {   e.preventDefault()
        console.log("guardando  modal");
        //let auxFechaAlt=moment( new Date()).format('YYYY-MM-DDTHH:mm:ss')
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')    
        let auxFech=calcularEdad(auxFechaNacimient)
        console.log(calcularEdad(auxFechaNacimient));           
        if (  verificaNombre(Nombre,Paterno,Materno) && valFechNacimiento()  && (!verificaINE(Curp,'CURP') /* && verificaCurp(Curp) */)
            && !verificaINE(NumIdentifi,'INE') && verificaUsu()  && valContra() ) //se cambio el verificar email por usuario
        {
            if ( !verificaNulosSelect(Genero,'Genero')  &&valDireccion() &&!verificaNulos(CExt,'NoExt') 
                && validaVariable(Cel,'Celular',10)  && validaVariable(Tel,'Telefono',10) 
               && !verificaNulos(Seccion,'Sección' ) && !verificaNulos(Colonia,'Colonia')  
            ) 
            {
                setMensaje('');
                //setTipoAdvertencia(tipoAdvert)  
                setAviso(false); 
                console.log(calcularEdad(auxFechaNacimient));
                llamadaNvoCliente( auxFechaNacimient+'T00:00:00',calcularEdad(auxFechaNacimient)) 
                //llamadaNvoCliente(auxFech)
                            
            }          
        }                                  
    }   
    function onChangeColonia(e){
        //setElemento(e.target.value)
        setNvoCliente({
            ...nvoCliente, //copiamos el nvocliente
            [e.target.name] : e.target.value  
        })
    }
 
    const DatosGenerales=(
        <div>                                
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingRight:1+'rem'}}
                label="Nombre"  value={Nombre} name='Nombre'size="small" //defaultValue="Nombre"
            />
            
            <TextField required id="Paterno-input" onChange={onChange} style={{paddingRight:1+'rem'}}
                label="Apellido Paterno"  value={Paterno} name='Paterno'size="small" //defaultValue="Paterno"
            />
            
            <TextField  id="Materno-input" onChange={onChange} style={{paddingRight:1.5+'rem',paddingBottom:1+'rem'}}
                label="Apellido Materno" value={Materno} name='Materno' size="small" //defaultValue="Materno"
            />
            <RelojFNacimiento/>
            <br/>
            <TextField required id="Curp-input" onChange={onChange} style={{paddingRight:1+'rem'}}
                label="CURP"  value={Curp} name='Curp' size="small"
                inputProps= {{ maxlength: 18 }} //defaultValue="Curp"
            />       
            <TextField required id="Curp-input" onChange={onChange} style={{paddingRight:1+'rem'}}
                label="INE" value={NumIdentifi} name='NumIdentifi' size="small"
                inputProps= {{ maxlength: 18 }} //defaultValue="INE" 
            />    
           {/*  <TextField required id="RFC-input" onChange={onChange} style={{paddingRight:1+'rem',paddingBottom:1+'rem',width:9.2+'rem'}}
                label="RFC" defaultValue="RFC" value={RFC} name='RFC' size="small"
                inputProps= {{ maxlength: 13 }}
            /> */}
             <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingRight:2+'rem',paddingBottom:1+'rem',}}
                 value={Email} name='Email' size="small" label="Usuario" //defaultValue="Email" 
                 autoComplete='off'
            />      
            <TextField
                required name="auxPass" label="Contraseña" type="password" id="auxPass"
                value={auxPass} onChange={onChangeContra} size="small" style={{width:8.5+'rem'}}      
                autoComplete='off'
            />    
            <br/>
            
            <FormControl component="fieldset" style={{paddingBottom:1+'rem',paddingRight:1+'rem'}} size="small" >              
                <FormLabel component="legend" style={{color:colorCheckR,fontWeight:500}}>
                 Genero
                </FormLabel>      
                <RadioGroup   row   aria-label="position" defaultValue="top"   
                    name="Genero" value={Genero} onChange={guardaCheckRad}
                >                                
                    <span> 
                        <FormControlLabel value={'H'} style={{color:colorCheckR}} 
                            control={<Radio color="primary" />} label={'Hombre'} />                                
                        <FormControlLabel value={'M'} style={{color:colorCheckR}} 
                            control={<Radio color="primary" />} label={'Mujer'} />
                    </span>                        
                </RadioGroup>                     
            </FormControl>  
            <TextField required id="Calle-input" onChange={onChange} style={{paddingRight:1+'rem',width:29.5+'rem'}}
                label="Calle" value={Calle} name='Calle' size="small" //defaultValue="Calle" 
              // inputProps= {{ maxlength: 18 }}
            />  
            <TextField  id="CExt-input" onChange={onChange} style={{paddingRight:1+'rem',width:4+'rem'}}
                label="NoExt" value={CExt} name='CExt' size="small" //defaultValue="CExt" 
              // inputProps= {{ maxlength: 18 }}
            />  
            <TextField  id="CInt-input" onChange={onChange} style={{paddingRight:1+'rem',width:3.5+'rem'}}
                label="NoInt"  value={CInt} name='CInt' size="small"  //defaultValue="CInt"
              // inputProps= {{ maxlength: 18 }}
            />  
            
            <br/>  
            {/* <TextField  id="Insta-input" onChange={onChange} style={{paddingRight:1+'rem',paddingBottom:1+'rem',width:6+'rem'}}
                label="Colonia"  value={Colonia} name='Colonia' size="small" //defaultValue="Colonia"
            />   
            
              <TextField  id="FaceSeccion-input" onChange={onChange} style={{paddingRight:1+'rem',paddingBottom:1+'rem',width:6+'rem'}}
                label="Sección"value={Seccion} name='Seccion' size="small" // defaultValue="Seccion" 
            /> 
            */}
           
          
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Celular" value={Cel} name='Cel' size="small" type="number" // defaultValue="Cel"
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            />  
      
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Telefono" value={Tel} name='Tel' size="small" type="number" //defaultValue="Tel" 
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            />
            {listSeccion.length!==0 ? 
               
               <FormControl  style={{paddingRight:1+'rem', minWidth:7.5+'rem',paddingBottom:1+'rem'}} >
                   <InputLabel id="tipo">Sección</InputLabel>
                   <Select  
                     label="Seccion" id="Seccion" name="Seccion"
                     value={Seccion} onChange={onChangeColonia}
                   >
                     {listSeccion.map((secc, index) => (
                       <MenuItem value={secc.Id} key={secc.Id}>
                         <em>{secc.Folio }</em>
                       </MenuItem>
                     ))}
                   </Select>
                </FormControl>
            :   null
            }
            {listColonia.length!==0 ? 
               
               <FormControl  style={{ minWidth:7.5+'rem'}} >
                   <InputLabel id="tipo">Colonia</InputLabel>
                   <Select  
                     label="Colonia" id="Colonia" name="Colonia"
                     value={Colonia} onChange={onChangeColonia}
                   >
                     {listColonia.map((col, index) => (
                       <MenuItem value={col.Id} key={col.Id}>
                         <em>{col.Nombre }</em>
                       </MenuItem>
                     ))}
                   </Select>
                </FormControl>
            :   null
            }
            
              <br/>                                           
            <TextField  id="Face-input" onChange={onChangeCorreo} style={{paddingRight:1.5+'rem',paddingBottom:1+'rem',width:10.5+'rem'}}
                label="Facebook" value={Face} name='Face' size="small" //defaultValue="Face" 
            /> 
            <TextField  id="Twit-input" onChange={onChangeCorreo} style={{paddingRight:1.5+'rem',paddingBottom:1+'rem',width:10.5+'rem'}}
                label="Twitter" value={Twit} name='Twit' size="small" //defaultValue="Twit" 
            />  
          
                                         
            <TextField  id="Insta-input" onChange={onChangeCorreo} style={{paddingRight:1+'rem',paddingBottom:1+'rem',width:10+'rem'}}
                label="Instagram" value={Insta} name='Insta' size="small" //defaultValue="Insta" 
            /> 
            <br/>
            <TextField  id="FaceSeccion-input" onChange={onChange} style={{paddingRight:1+'rem',paddingBottom:1+'rem',width:38+'rem',}}
                label="Observacion" value={Obser} name='Obser' size="small" //defaultValue="Obser" 
            />
        
        </div>
        
        
    )
   
    
 
    return (
        <>           
         <Modal
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}        
        >
            {DatosGenerales}
        </Modal> 
            
        </>
    )
}

export default EditarUsuario
