import React, {useState, useEffect} from "react";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import ModalInformativo from '../generales/ModalInformativo';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { IconButton, Box , Paper, Typography, List, LinearProgress, Card, CardContent, Container} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import NumberFormat from 'react-number-format';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const useStyles = makeStyles((theme) => ({
    root: {
      alignItems: 'center',
    },
    imagen:{
        width: "75%",
        minHeight: 450,
        overflow: 'auto',
        marginTop:'1em'
    },
    tamanioFecha:{
        height:9,
        marginTop:4,
        fontSize:12,
    },
    tamanioLog:{
        width:'96%',
        marginLeft:'.4em',
        fontSize:12,
        padding:0
    },
    estiloCards:{
        marginBottom:'.3em',
        marginRight:'.6em',
    },
    alturaLista:{
        minHeight: 450,
        maxHeight: window.innerHeight-200,
        overflow: 'auto',
        marginTop:'1em',
    },
}));

export default function InfoArchivo ({modalAbierto,setModalAbierto,archivo,indice, procedSeleccionado}){
    const classes = useStyles();
    const [listaBitacora, setListaBitacora] = useState([]);
    const [espera, setEspera] = useState(false);
    const historial = useHistory();   
    const [arch, setArch] = useState(archivo);
    const [urlVisualizador, setUrlVisualizador] = useState("");
    const [cargando, setCargando] = useState(false);
    const [fechaEnvio,setFechaEnvio] = useState("");
    useEffect(()=>{
        async function consultarBitacora(){
            setEspera(true);
            let auxU = process.env.REACT_APP_LINK + `/procedimiento-cliente-doc-id-historial`;
            let token = localStorage.getItem("token20");
            let usuario = localStorage.getItem("UsuId");
            let id = archivo.CliDocId;
            let dataInfo = qs.stringify({
                Documento: id,
                Usuario: usuario,
                idDispositivo:"987yugbhcdsscs"
            });
            let config = {
              url: auxU,
              method: "POST",
              data: dataInfo,
              headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              cancelToken: source.token,
            };
            let aux = {};
            await axios(config)
              .then(async (response) => {
                aux = response.data;
                console.log(aux);
                if (aux[0].HistoId>-1) {
                    setListaBitacora(aux);
                    let fechaaux= aux[aux.length-1].HistoFReg
                    setFechaEnvio(fechaaux)
                }
              }).catch(function (error) {
                console.log(`Error: ${error}`);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                  console.log('datos incorrectos' );  
                }else
                {
                  if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                      ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                  {
                    console.log("Token inválido");
                    localStorage.clear();                      
                    historial.push("/");                              
                  } 
                }
              });
              setEspera(false)
        }
        setCargando(true);
        consultarBitacora();
        setArch(archivo);
        setUrlVisualizador(`https://docs.google.com/gview?url=${archivo.Direcctorio}${archivo.Archivo}&embedded=true`);
        setCargando(false);
        console.log(archivo)
        console.log(arch.Extension.substring(arch.Extension.lastIndexOf(".")+1,arch.Extension.length))
    },[])


    function listarBitacora(){
        let arrayElementos =[];
        listaBitacora.map((bitacora)=>{
            arrayElementos.push(
                <Card className={classes.estiloCards}>
                     <Box display="flex" flexDirection="column" >
                        <Box display="flex" flexDirection="row" >
                                <AccessTimeIcon style={{color:'#0F82EE', width:'.5em',marginRight:3}}/>
                            <Typography className={classes.tamanioFecha}>
                                <b>{moment.utc(bitacora.HistoFReg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                ? "" : moment.utc(bitacora.HistoFReg).format('DD/MM/YYYY HH:mm')}</b></Typography>
                        </Box>
                        <Typography className={classes.tamanioLog}>
                            {bitacora.HistoDescr}
                        </Typography>
                        </Box>
                </Card>
            )      
        })
        return arrayElementos;
    }
    const mostrarBitacora = listaBitacora.length>0 ? listarBitacora() : <p>Sin resultados</p>;
    const listado = espera ?  (<Box><LinearProgress/> </Box>) :( mostrarBitacora )
    const mostrarModal = urlVisualizador.length>0 ? generarModal() : <p>Sin Archivo Disponible</p>
    const modalArchivo = cargando ?  (<Box><LinearProgress/> </Box>) :( mostrarModal);

    function generarModal (){
        return(
        <Box className={classes.root}>
        <Box  flexWrap="wrap">
                <Typography variant="h7" component="h7" style={{marginRight:'.3em'}}>
                <b>{arch.Archivo}</b>
                </Typography>
                <Typography variant="h7" component="h7" style={{marginRight:'.5rem',paddingRight:.5+'rem'}}>
                <b><NumberFormat decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} displayType="text" value={arch.Size/Math.pow(1024,1)}/> KB</b>
                </Typography>
                <Typography variant="h7" component="h7" color="primary"  style={{marginRight:'.3em'}}>
                <b>Fecha Envío</b>
                </Typography>
                <Typography variant="h7" component="h7" style={{marginRight:'.3em'}}>
                <b>{fechaEnvio.length>0 ?(moment.utc(fechaEnvio).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                ? "" : moment.utc(fechaEnvio).format('DD/MM/YYYY HH:mm')) : null}</b>
                </Typography>
        </Box>
            <Paper style={{marginBottom:'.2em', alignContent:'center', display: 'flex', flexDirection:'row', backgroundColor:'#f5f5f5', marginRight:'0px'}}>
                <iframe className={classes.imagen} title={`archivo${indice}`} id={`archivo${indice}`}
                        alt="documento" src={urlVisualizador}/>
                <List className={classes.alturaLista} style={{marginLeft: '.5em',width:"25%", backgroundColor:'#f5f5f5'}}>
                    {listado}
                </List>
            </Paper>      
        </Box>)
    }

    return (
        <ModalInformativo setModalAbierto={setModalAbierto}
            modalAbierto={modalAbierto} titulo={procedSeleccionado.ProcesoNom+" "+archivo.DocAlias}
            tamanio='l' colorFondo='#f5f5f5'
        >
           {modalArchivo}
        </ModalInformativo>
    )
}