import React,{useState,useEffect} from 'react';
import PanelProfesion from './BusqPanelProfesion';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Card,TextField,Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '16rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1.25rem',
        maxHeight: window.innerHeight-170,
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '14rem',
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

const BusqTablaTrabajo = ({datosJS}) => {
    const classes = useStyles();
    const [datos,setDatos] = useState([]);
    const [contador,setContador] = useState(0)
    //console.log(contador);
    const [trabajoSeleccionado,setTrabajoSeleccionado]=  useState(-1);      
    const [promotor,guardarPromotor] = useState({ nombre:''});
    const [registro,setRegistro]=useState("");
    const[infoPromot,setinfoPromot]=useState("");
    
    useEffect(()=>{
        setDatos(datosJS)
        setContador(datosJS.length)
    },[])

    const RenglonNum =(valorId,regis)=>{
       // console.log(valorId);
        setTrabajoSeleccionado(valorId);
        let info=datos.filter((dat)=>dat.Trabajo=== valorId) ;
        //console.log(info[0].titulo);
        //localStorage.setItem('PresidenteMun',''+info[0].titulo);
        setRegistro(regis);
        //setinfoPromot(info[0]);
    }

   
    function filtro(){        
        let auxCadena=nombre.trim();                 
        let info=datos.filter((dat)=>dat.Trabajo.search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info,contador);
    }
    function TablaPromo(datos,total) {
        let numRenglon=0;
        return(
            <TableContainer className={classes.container} id="tamTablaTrabajo">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow>            
                            <TableCell style={{padding: 0.2 +'rem'}} ><span className={classes.totalEstilo}>{total} </span><span className={classes.tituloEstilo}>Trabajo</span></TableCell>                                                                                                
                        </TableRow>                        
                    </TableHead>  
                    <TableBody>                                  
                        {datos.map((auxTrabajo) => {
                            //console.log(auxNombre);
                            numRenglon++;                                                                                                            
                            return (                  
                                <TableRow  className={classes.tableRow} selected={ trabajoSeleccionado === auxTrabajo.Trabajo}
                                classes={{ hover: classes.hover, selected: classes.selected }}  key={numRenglon} onClick={ () => RenglonNum(auxTrabajo.Trabajo,auxTrabajo) }  >                                
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}><span>{auxTrabajo.Trabajo}  </span></TableCell>                                                                        
                                </TableRow>              
                                );                        
                            })
                        }                                                
                    </TableBody>
                </Table>
            </TableContainer>
        ); 
    }

    const {nombre} = promotor;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };
    //const datGeneral = datosJS&&registro? (<div className="pl-5"><DatosGeneral valor={registro}/> <Graficas valor={registro}/></div>):(null)
    const datGeneral = datos&&registro? (<PanelProfesion profesionId={trabajoSeleccionado}  profesion={registro}/>):(null)
    const contenido = promotor? (filtro()):(TablaPromo(datos,contador));    
    const llamada= datos.length!=0 &&contador>0?(contenido):(<div><h2>No contiene datos</h2></div>)

    return (
            <Box display={'flex'} flexDirection="row">
                <Card className={classes.estiloCard}>                        
                    <div>
                        <div>            
                            <div className={classes.formControl}>
                                <TextField
                                    name="nombre"                         
                                    placeholder="Trabajo"
                                    value={nombre}
                                    onChange={onChange}/>
                            </div>
                        </div>		            
                        {llamada}
                    </div>
                </Card>
                {datGeneral}
            </Box>                    
      );
}
 
export default BusqTablaTrabajo;