import React,{useState,useEffect} from 'react';
import TablaProfesion from './BusqTablaxProfesion'
import TablaSinProf from './BusqTablaSinProf'
import axios from 'axios';
import qs from 'qs';
import { Box,LinearProgress} from '@material-ui/core';
import { useHistory } from "react-router-dom";



const BusqPanelProfesion = ({profesionId,profesion}) => {
    const [datos,setDatos] = useState([]);
    let history = useHistory();
    const[espera,setEspera]=useState(false);
    const [vacio,setVacio] = useState(false); 
    const [actualizar,setActualizar] = useState(0); 
   
    useEffect(()=>{ 
        const source = axios.CancelToken.source();
        async function conectarJSon()  {            
            setEspera(false);
            let auxU=process.env.REACT_APP_LINKRED2 +`/afiliado-listxusuario-profesion`;               
            let token =localStorage.getItem('token20') ;         
            let dataInfo = qs.stringify({
                'Profesion': profesionId,
                'idDispositivo': 'csdq21weddewde' 
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            setVacio(true)
           // console.log("antes del axios");
            await axios(config)
            .then(function (response) {               
                aux=  response.data;      
                console.log(aux);           
                if (aux.resultado) 
                {  console.log(aux.resultado);             
                    setDatos(aux.resultado);
                    setVacio(true)
                }else
                {
                    setDatos([]);
                    setVacio(false)
                }                                            
            })
            .catch(function (error) {                                
               // console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                                       
                        history.push("/");
                    } 
                }
            });
            setEspera(true)  ;            
        };           
        conectarJSon();
        return ()=> {
            source.cancel();
        }               
},[profesionId,actualizar]);


const carga = vacio ?(<Box pt={3}><LinearProgress/> </Box>):(<div><TablaSinProf   setActualizar={setActualizar} actualizar={actualizar}/></div>);
const content = espera && datos.length!=0 ? (<div > <TablaProfesion datosJS={datos}   setActualizar={setActualizar} actualizar={actualizar}/>    </div> ) : ( carga);

    return (
        <div>            
            {content}
           
         </div>
      );
}
 
export default BusqPanelProfesion;