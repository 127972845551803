import React from 'react';

import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: '60rem',
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      overflowX:'auto',
      backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
    width:'30em'
    },
    titulos:{
        padding: 0.2 +'rem', 
        color:'#0F82EE',
        fontWeight:600
    },
    elementos:{
        alignContent:'center',
        padding: 0.2 +'rem'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });


const BusqTablaSinPromov = ({setActualizar,actualizar}) => {
    const classes = useStyles();
        
        function TablaSinPromo() {            
            //console.log(tot);
            return(
                <TableContainer className={classes.container}>
                    <Table  stickyHeader aria-label="sticky table" >
                        <TableHead >    
                            <TableRow>
                                <TableCell style={{padding: 0.2 +'rem'}}>Total </TableCell>
                                <TableCell style={{padding: 0.2 +'rem'}}>Agregar</TableCell>
                                <TableCell style={{padding: 0.2 +'rem'}}>Ver</TableCell>               
                                <TableCell style={{padding: 0.2 +'rem'}}>Sección</TableCell> 
                                <TableCell style={{padding: 0.2 +'rem'}}>Nombre</TableCell>               
                                <TableCell style={{padding: 0.2 +'rem'}}>Preferencia</TableCell>                                                        
                                <TableCell style={{padding: 0.2 +'rem'}}>Tel/cel</TableCell>
                                <TableCell style={{padding: 0.2 +'rem'}}>Dirección</TableCell>   
                            </TableRow>
                                                    
                        </TableHead>  
                        <TableBody>          
                            
                            <TableRow >
                                <TableCell className={classes.tableCell} style={{padding: 0 +'rem'}}>Sin</TableCell>
                                <TableCell className={classes.tableCell} style={{padding: 0 +'rem'}}>Afiliado</TableCell>
                                <TableCell className={classes.tableCell} style={{padding: 0+'rem'}}></TableCell>                                                                
                                <TableCell className={classes.tableCell} style={{padding: 0+'rem'}}>Registrados</TableCell>                                                                                                                   
                                <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}></TableCell>
                                <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}></TableCell>
                                <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}> </TableCell>
                                <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}}></TableCell>                                                    
                            </TableRow>              
                                                            
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
    
	
    /*const btnActualizar=e=>{
        e.preventDefault();
        console.log("entrado albtn "+actualizar);  
        setActualizar(actualizar+1)
    }
    const btnRecargar = <a className="pt-1 pl-2"  onClick={btnActualizar}>
                            <img src="ic_actualizar.svg"  alt="" border="0" height="30" width="30"/> 
                        </a>*/
    
   // const datGeneral =registro? ( {/* <LlenaPromovidos promid={tabla2}/> */} ):(null)
    //const contenido = promovido? (filtro()):(TablaPromo(datos));
    const llamada= (TablaSinPromo())
    return (       
        <div>
            {llamada}          
        </div>
           
     
    );
}
 
export default BusqTablaSinPromov;