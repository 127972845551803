import { Box, LinearProgress, Typography,IconButton ,Tooltip} from '@material-ui/core'
import React from 'react'
import { useHistory } from "react-router-dom";
import Tabla from './TablaClienteProcedimiento'
import ArchivosInicio from '../archivos/ArchivosInicio'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import {ClienteContext} from '../context/ClienteContext'
import { authUser } from '../funciones/AuthUser'
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NuevoProcedimientoCliente from './NuevoProcedimientoCliente';
import axios from 'axios';
import qs from 'qs';
const PanelProcedimiento = () => {
    const {clientSeleccionado}=React.useContext(ClienteContext);  
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    let history = useHistory();     
    const[auxCliente,setAuxCliente]=React.useState([])
    const[procedSeleccionado,setProcedSeleccionado]=React.useState([])
    const[visualizar,setVisualizar]=React.useState(false)
    const [listaStatus, setListaStatus] = React.useState([]);
    const[actualizar,setActualizar]=React.useState(true);
    const [modalProcAbierto, setModalProcAbierto] = React.useState(false);
    const [tituloProcCli, setTituloProcCli] = React.useState("");
    React.useEffect(()=>
    {   const usu= localStorage.getItem('UsuarioNom');
        const usuID= localStorage.getItem('UsuId');
        const nvl= localStorage.getItem('Tipo');
        let cliDat = localStorage.getItem('cliDat');
        let cliDat2=JSON.parse(cliDat)
        const source = axios.CancelToken.source();    
        async function getListadoStatus(){
            let auxU = process.env.REACT_APP_LINK + `/procedimiento-status-list`;
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({
              idDispositivo: "987ygubhjnjik",
            });
            let config = {
              url: auxU,
              method: "POST",
              data: dataInfo,
              headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              cancelToken: source.token,
            };
            let aux = {};
            await axios(config)
              .then(async (response) => {
                aux = response.data;
                if (aux[0].Id>-1) {
                  setListaStatus(aux);
                }
              })
              .catch(function (error) {
                console.log(`Error: ${error}`);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                  console.log('datos incorrectos' );  
                }else
                {
                  if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                      ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                  {
                    console.log("Token inválido");
                    localStorage.clear();                      
                    history.push("/"); 
                    guardarUsuario([])                                  
                  } 
                }
              });
          }          
        //console.log(cliDat2);
        //console.log(clientSeleccionado);
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                || (cliDat===undefined||cliDat===''||cliDat===null)||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {
                if (usuario.length === 0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                         
                    
                }
                if (clientSeleccionado.length === 0 && cliDat2) 
                {
                    setAuxCliente(cliDat2)
                }
                else
                {
                    setAuxCliente(clientSeleccionado)
                }
                setVisualizar(true)        
                document.title = 'Procedimientos'                     
                guardarGenerales({...generales,Titulo:'Procedimientos'})         
                getListadoStatus();                                      
            }            
        }        
	    veriSesion();	     
    },[])
    function abrirModalProc(){
        setModalProcAbierto(!modalProcAbierto);
        setTituloProcCli("Nuevo Procedimiento de "+auxCliente.nombre);
    }
    const DatosCliente=
        <div>
            <Box display="flex" row width="100%"   flexWrap="wrap">           
                <Tooltip title={`Actualizar tabla`}>
                    <IconButton    size="small" onClick={() => setActualizar(!actualizar)} 
                        style={{marginRight:.6+'rem'}} component="span" aria-label="refresh"
                    >                
                        <RefreshIcon  color="primary" style={{ marginBottom:6+'px'}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Nuevo Procedimiento`}>
                    <IconButton   size="small"  onClick={() => abrirModalProc()}  
                        component="span" style={{marginRight:.6+'rem'}}  aria-label="add circle"
                    >
                        <AddCircleIcon style={{color:'#0F82EE',marginBottom:4+'px'}}  />  
                    </IconButton>
                </Tooltip>
                <Box display="flex" pr={1}>
                    <Typography variant="h6" component="h2" color="primary">
                        {'NSS'}
                    </Typography>
                </Box>
                <Box display="flex" pr={3}>
                    <Typography variant="h6" component="h2" >
                        {auxCliente.nss }
                    </Typography>
                </Box>
                <Box display="flex" pb={1}>
                    <Typography variant="h6" component="h2" color="primary">
                        {auxCliente.nombre}
                    </Typography>
                </Box>                                
            </Box>     
        </div>
    const auxDatos =auxCliente.length!==0 ? DatosCliente:null
    const modProcClie = modalProcAbierto ? 
                        ( <NuevoProcedimientoCliente modalAbierto={modalProcAbierto}  IdCli={auxCliente.IdCli}
                            setModalAbierto={setModalProcAbierto} titulo={tituloProcCli}
                            setActualizar={setActualizar} actualizar={actualizar}
                          />
                        ): null;
    return (
        <div>
         {  visualizar ? <> {auxDatos}
            {auxCliente.length!==0 ?<Box display="flex" row width="100%"  flexWrap="wrap">
                <Box display="flex" width={780} >
                    <Tabla IdCli={auxCliente.IdCli} setProcedSeleccionado={setProcedSeleccionado} 
                        clinombr={auxCliente.nombre} setActualizar={setActualizar} actualizar={actualizar}
                    />
                </Box>
                <Box display="flex" width="35%">
                    {procedSeleccionado.length!== 0 ?<ArchivosInicio procedSeleccionado={procedSeleccionado} listaStatus={listaStatus}/> :null}
                </Box>                
            </Box>
            :<Box>
                <LinearProgress/>
            </Box>
            
            }</>
            :null}
            {modProcClie}
        </div>
    )
}

export default PanelProcedimiento
