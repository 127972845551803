import React,{useState,useEffect} from 'react';
//import Graficas from './MGraficas2';
//import ModalDatGen from './BusqDatGenModal';
import ReactTooltip from "react-tooltip";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: '60rem',
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      overflowX:'auto',
      backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
    width:'30em'
    },
    titulos:{
        padding: 0.2 +'rem', 
        color:'#0F82EE',
        fontWeight:600
    },
    elementos:{
        alignContent:'center',
        padding: 0.2 +'rem'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

const BusqTablaxProfesion = ({datosJS}) => {
    const classes = useStyles();
    let datos=datosJS;
    
    let contador=datos.length;
  //  console.log(contador);
    const [seleccionado,setSeleccionado]=  useState(-1);      
    const [promotor,guardarPromotor] = useState({ nombre:''});
    const [registro,setRegistro]=useState("");
    const[infoPromot,setinfoPromot]=useState("");
    const [mensaje2,setMensaje2]=useState("");

    useEffect(()=>{
        
    },[])
    const RenglonNum =(valorId,regis)=>
    {       
        setSeleccionado(valorId);
        let info=datos.filter((dat)=>dat.Id=== valorId) ;
        setRegistro(regis);
        setinfoPromot(info[0]);
    }

    function filtro(){        
        let auxCadena=nombre.trim();                 
        let info=datos.filter((dat)=>dat.NombreFull.search(auxCadena.toUpperCase() ) !== -1)         
        return TablaPromo(info,contador);
    }
    const llamdaModal2=()=>{
        setMensaje2("is-active")
    }
    function TablaPromo(datos,total) {
        let numRenglon=0;
        return(
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow >                                        
                            <TableCell className={classes.titulos} ><span className="is-size-5 ">{total} </span></TableCell>                                  
                            <TableCell className={classes.titulos}>Indep.</TableCell>    
                            <TableCell className={classes.titulos}>Teléfono</TableCell>
                            <TableCell className={classes.titulos} >Celular</TableCell>
                            
                            <TableCell className={classes.titulos}>Afiliado</TableCell>                                        
                            <TableCell className={classes.titulos}>Email</TableCell>   
                            <TableCell className={classes.titulos}>Edad</TableCell>                                        

                            
                        </TableRow>                        
                    </TableHead>  
                    <TableBody>                                  
                        {datos.map((postulante) => {
                            let auxNombre="";
                            auxNombre=postulante.NombreFull;    
                            //console.log(auxNombre);
                            numRenglon++;                                                                                                        
                            return (                  
                                <TableRow className={classes.tableRow} selected={ seleccionado === postulante.Id}
                                classes={{ hover: classes.hover, selected: classes.selected }} key={numRenglon}  onClick={ () => {RenglonNum(postulante.Id,postulante)} }  >                                                                                                                                      
                                    <TableCell style={{padding: 0+'rem'}}>
                                        {postulante.Id ===0?null: <span data-tip="Información del Afiliado">
                                            <a className="" /*onClick={llamdaModal2}*/> <img src={seleccionado === postulante.Id ? "menuBlanco.svg" : "menu.svg"}  alt="" border="0" height="20" width="20"/> </a>  
                                        </span>                 }             
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{postulante.Independiente === 1?"Sí":"No"}</TableCell>                                                                                                        
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{postulante.Telefono}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{postulante.Celular}</TableCell>
                                    
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{auxNombre}</TableCell>      
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{postulante.Email==="@"?null:postulante.Email}</TableCell>
                                    <TableCell className={classes.tableCell} style={{padding: 0.2 +'rem'}} >{postulante.Edad}</TableCell>
                                </TableRow>              
                                );                        
                            })
                        }                                                
                    </TableBody>
                </Table>
            </TableContainer>
        ); 
    }

    const {nombre} = promotor;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };
    //const datGeneral = datosJS&&registro? (<div className="pl-5"><DatosGeneral valor={registro}/> <Graficas valor={registro}/></div>):(null)
    const contenido = promotor? (filtro()):(TablaPromo(datos,contador));
    //const contenido = (TablaPromo(datos));
    const llamada= datosJS?(contenido):(<div><h2>No contiene datos</h2></div>)
    //const modal2= mensaje2? (<ModalDatGen datGen={registro} mostrar={mensaje2} setMensaje={setMensaje2}/>):(null);      
    return (
        <div>
                <div>                        
                    <div>
                        <div>            
                            <div className={classes.formControl}>
                                <TextField className={classes.buscador}
                                    name="nombre"                         
                                    placeholder="Afiliado"
                                    value={nombre}
                                    onChange={onChange}/>
                            </div>
                            <ReactTooltip place="left" type="success" effect="solid" />  
                        </div>		            
                        {llamada}
                    </div>
                </div>
                {/*modal2*/}
        </div>
      );
}
 
export default BusqTablaxProfesion;