import React, { useEffect, useState  } from "react";
import axios from "axios";
import qs from "qs";
import ModalInfo from '../generales/ModalInformativo'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { IconButton, LinearProgress, Box , List, Card,Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();


const useStyles = makeStyles((theme) => ({
  
  alturaLista:{
      minHeight: 180,
      maxHeight: window.innerHeight-350,
      overflow: 'auto',
      alignContent:'center',
  },
  estiloCards:{
    marginBottom:'.3em',
    height:25
  },
  tamanioFecha:{
    height:9,
    fontSize:12,
    marginTop:5
  },
    tamanioLog:{
      marginLeft:'.4em',
      fontSize:12,
      marginTop:5
  },
}));

export default function BitacoraArchivo ({modalAbierto,setModalAbierto,titulo,archivo}){
    const [listaBitacora, setListaBitacora] = useState([]);
    const [espera, setEspera] = useState(false);
    const historial = useHistory();   
    const classes = useStyles();
    useEffect(()=>{
        async function consultarBitacora(){
            setEspera(true);
            let auxU = process.env.REACT_APP_LINK + `/procedimiento-cliente-doc-id-historial`;
            let token = localStorage.getItem("token20");
            let usuario = localStorage.getItem("UsuId");
            let id = archivo.CliDocId;
            let dataInfo = qs.stringify({
                Documento: id,
                Usuario: usuario,
                idDispositivo:"987yugbhcdsscs"
            });
            let config = {
              url: auxU,
              method: "POST",
              data: dataInfo,
              headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              cancelToken: source.token,
            };
            let aux = {};
            await axios(config)
              .then(async (response) => {
                aux = response.data;
                if (aux[0].HistoId>-1) {
                  console.log(aux);
                    setListaBitacora(aux);
                }
              }).catch(function (error) {
                console.log(`Error: ${error}`);
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                  console.log('datos incorrectos' );  
                }else
                {
                  if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                      ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                  {
                    console.log("Token inválido");
                    localStorage.clear();                      
                    historial.push("/");                              
                  } 
                }
              });
              setEspera(false)
        }
        consultarBitacora();
    },[])

  function listarBitacora(){
      let arrayElementos =[];
      listaBitacora.map((bitacora)=>{
          arrayElementos.push(
          <Card className={classes.estiloCards}> 
            <Box display="flex" flexDirection="row">
                  <AccessTimeIcon style={{color:'#0F82EE', width:'.6em', marginRight:3,marginTop:1.2}}/>
              <Typography className={classes.tamanioFecha}>
                <b>{moment.utc(bitacora.HistoFReg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                    ? "" : moment.utc(bitacora.HistoFReg).format('DD/MM/YYYY HH:mm')}</b>
              </Typography>
              <Typography className={classes.tamanioLog}>
                {bitacora.HistoDescr}
              </Typography>
            </Box>
          </Card>)      
      })
      return arrayElementos;
  }
  const mostrarBitacora = listaBitacora.length>0 ? listarBitacora() : <p>Sin resultados</p>;
  const listado = espera ?  (<Box><LinearProgress/> </Box>) :( mostrarBitacora )
  return (
      <ModalInfo setModalAbierto={setModalAbierto}
          modalAbierto={modalAbierto} titulo={titulo+' '+archivo.DocAlias}
          tamanio='md' colorFondo='#f5f5f5'
      >
        <List className={classes.alturaLista} style={{width:"100%"}}>
        {listado}
        </List>
          
          
      </ModalInfo>
  )
}