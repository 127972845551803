import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {LinearProgress, Box, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    fuenteTamanio:{
        fontSize:12,
        height:'2em',
    },
}));

export default function Progress({progress}){
    const classes = useStyles();
    return(
        <Box sx={{ display: 'flex', alignItems: 'center', height:'2%'}}>
                <Box sx={{ width: '70%'}}>
                    <LinearProgress variant="determinate" value={progress}/>
                </Box>
                <Box sx={{ width: '15%', marginLeft:'.3em'}}> 
                    <Typography className={classes.fuenteTamanio}>{progress+"%"}</Typography>
                </Box>
        </Box>
    )
}